import React,{useState,useEffect,useContext} from 'react';
import { useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCar, faLocationDot, faMapLocationDot, faScrewdriverWrench,faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { faBell} from '@fortawesome/free-regular-svg-icons'
import { UserOutlined,
  EnvironmentOutlined,
  PoweroffOutlined,
  ApartmentOutlined,
  WarningOutlined,AppstoreOutlined,DownOutlined,CheckOutlined,UpOutlined, BarChartOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
  import { ValueContext } from "../../context/Context.js";
import { Layout, Menu, theme, Badge,Typography, Button,Dropdown,Space,Avatar,Row,Col,Modal as DeskModal } from "antd";
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes.js';
import * as actions from '../../constants/action_types.js';
import { GET_NOTREEDNOTIFICATIONS, GET_NOTREEDMAINTNOTIFICATIONS } from "../../queries/notificationQuery.js";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch } from 'redux-react-hook';
import { useLocation,Outlet } from "react-router-dom"
import { Modal, TabBar } from 'antd-mobile';
import "../../css/SideBar.css"
import axios from "axios";
const { Sider,Footer } = Layout;
const { Title,Text } = Typography;
const { confirm } = DeskModal;


function Dashbord(props,{ children }) {
  const { role, company, _id, allworkspaces, fullworkspaces,companyname } = props.props;
  const { value, setValue,valuemaint,setValuemaint,isDesktop } = useContext(ValueContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [nameworckspace, setnameworckspace] = useState(localStorage.getItem("nameworkspace")?localStorage.getItem("nameworkspace"):role==="Admin"?companyname:"Allspace");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sampleLocation = useLocation();
  const { data,refetch:refetchAlert } = useQuery(GET_NOTREEDNOTIFICATIONS, {
    variables: { _id: company, role: role, user: _id },
  });
  const { data:datam,refetch:refetchMaint } = useQuery(GET_NOTREEDMAINTNOTIFICATIONS, {
   variables: { _id: company, role: role, user: _id },
  });
  const subscription = JSON.parse(localStorage.getItem("subscription"));
   const socket = io({ path: "/socket.io", transports: ["websocket"] });
  // const socket = io(process.env.REACT_APP_SERVER_URL, {
  //   withCredentials: true, // send cookies with the request (if any)
  //   extraHeaders: {
  //     "Access-Control-Allow-Origin": process.env.REACT_APP_CLIENT_URL, // specify allowed origin
  //   },
  // });
 useEffect(() => {
  if (socket) {
    socket.on(_id+'updateMainNotification', (Msg) => {
      if(Msg==="Notification Deleted"){refetchMaint();};
    });
    socket.on(_id+'MaintenanceNotification', (Msg) => {
      if(Msg==="Maintenance Notification"){refetchMaint();};
    });
    socket.on(_id+'alertNotification', (Msg) => {
       refetchAlert(); 
    });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [socket, data]);
    useEffect(() => {
      if (data) {
        setValue(data.getNotReedNotifications);
      }
      if (datam) {
        setValuemaint(datam.getNotReedMaintNotifications);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, datam]);
    function logout() {
      confirm({
        title: t("logout"),
        icon: <ExclamationCircleFilled />,
        okText: t("FormValidations.11"),
        okType: "danger",
        cancelText: t("FormValidations.12"),
        async onOk() {
          dispatch({ type: actions.SET_AUTH_USER, authUser: null });
          localStorage.removeItem('token');
          localStorage.removeItem("worckID");
          localStorage.removeItem("nameworkspace");
          localStorage.removeItem("subscription");
          if (subscription) { 

            // Send the token to your server
            const requestBody = {
              query: `
              mutation {
                removeUserSub(subscriptions: {
                endpoint: "${subscription.endpoint}",
                expirationTime: ${subscription.expirationTime ? `"${subscription.expirationTime}"` : null},
                keys: {
                  p256dh: "${subscription.keys.p256dh}",
                  auth: "${subscription.keys.auth}"
                  }
                  }, _id: "${_id}") {
                    _id
                    }
                    }
                    `,
                  };
                  
                  const { data1 } = await axios.post(
                    "/graphql",
                    requestBody
                  );
                }
                if ("serviceWorker" in navigator) {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                      for (let registration of registrations) {
                        registration.unregister().then(() => {
                          console.log(
                            "Service worker unregistered:",
                            registration
                          );
                        });
                      }
                    })
                    .catch(function (error) {
                      console.error(
                        "Error during service worker unregistration:",
                        error
                      );
                    });
          }
          if ("caches" in window) {
            // Delete all cache entries
            caches.keys().then(function (cacheNames) {
              cacheNames.forEach(function (cacheName) {
                caches.delete(cacheName).then(() => {
                  console.log(`Cache ${cacheName} deleted`);
                });
              });
            });
          }

          navigate("/login")
        },
        onCancel() {
          console.log("Cancel");
        },
      });
      
  }
  console.log(props.props);
   const handleUpdateAuthUser = (worckspace) => {
     let newAuthUser = {
       ...props.props, // Keep the existing properties
       // Modify the properties you want to update
     };
     if (worckspace === "all") {
       if (role==="Admin") {
         newAuthUser.workspaces = null;
       } else {
         newAuthUser.workspaces = allworkspaces;
       }
     } else { newAuthUser.workspaces = worckspace }
     props.changeAuthUser(newAuthUser);
   };
   const handleOpenChange = (flag) => {
       setOpen1(flag);
     };
    function getItem(label, key, icon, children,title=false) {
      return {
        key,
        icon,
        children,
        label,
        title
      };
    }
    const {
      token: { colorBgContainer },
    } = theme.useToken();
    const tabs = [
      {
          key: '/map',
          icon: <Link to="/map"><FontAwesomeIcon icon={faLocationDot} /></Link>,
          title:t("SideBar.Map"),
      },
      {
          key: '/inventory',
          icon: <Link to="/inventory"><FontAwesomeIcon icon={faCar} /></Link>,
          title:'Vehicules',
          
      },
      role!=="Super Admin"&&{
          key: '/alerts/notification',
          icon: <Link to="/alerts/notification"><FontAwesomeIcon icon={faBell} /></Link>,
          badge: value>0 ? value:null,
          title:t('AlertTab.notification'),
      },
      role==="Super Admin"&&{
        key: '/administration',
        icon: <Link to="/administration"><ApartmentOutlined /></Link>,
        title:'Administration',
    },
      {
          key: 'autre',
          icon:<Link><AppstoreOutlined  /></Link>,
          title:t('Autre'),
      },
  ];
    const tabs2 = [
      {
        key: '/alerts',
        icon: <Link to="/alerts"><WarningOutlined /></Link>,
        title:t("SideBar.Alerts"),
        
      },
      {
        key: '/maintenance',
        icon: <Link to="/maintenance"><FontAwesomeIcon icon={faScrewdriverWrench} /></Link>,
        title:'Maintenance',
          
      },
      role!=="User"&&{
          key: '/administration',
          icon: <Link to="/administration"><ApartmentOutlined /></Link>,
          title:'Administration',
      },
    ];
    const tabs3 = [
      // {
      //     key: '/zone',
      //     icon: <Link to="/zone"><FontAwesomeIcon icon={faMapLocationDot} /></Link>,
      //     title:'Zones',
      // },
      {
        key: '/user',
        icon: <Link to="/user"><UserOutlined /></Link>,
        title:t("SideBar.Profile"),
        
      },
      {
        key: 'logout',
          icon: <Link onClick={logout}><PoweroffOutlined /></Link>,
          title:t("SideBar.Logout"),
          
      },
  ];
    const items = [
      getItem(<Link to="/map">{t("SideBar.Map")}</Link>, '/map', <EnvironmentOutlined />),
      getItem(<Link to="/inventory">{t("SideBar.Inventory")}</Link>, '/inventory', <FontAwesomeIcon icon={faWarehouse} />),
      getItem(<Link to="/zone">Zones</Link>, '/zone', <AppstoreOutlined  icon={faWarehouse} />),
      role!=="Super Admin"&&getItem(<><Link to="/alerts">{t("SideBar.Alerts")} {value>0&&<Badge size="small" count={value}/>}</Link></>, '/alerts', <WarningOutlined /> ),
      getItem('Rapports', '/racpports', <BarChartOutlined />, [
        // getItem(<Link to="/Carb-Conso">{t('Conso. Carburant')}</Link>, '/Carb-Conso'),
        // getItem(<Link to="/Carb-Reful">{t('Remp. Carburant')}</Link>, '/Carb-Reful'),
        getItem(<Link to="/IdelReports">{t('TauR')}</Link>, '/IdelReports'),
        //getItem(<Link to="/Carb-Vol">{t('Vol Carburant')}</Link>, '/Carb-Vol'),
      ]),
      role!=="Super Admin"&&getItem(<Link to="/maintenance">Maintenance {valuemaint>=0&&<Badge size="small" count={valuemaint} />}</Link>, '/maintenance', <FontAwesomeIcon icon={faScrewdriverWrench} />),
      role!=="User"&&getItem(<Link to="/administration">Administration</Link>, '/administration', <ApartmentOutlined />),
      getItem(<Link to="/user">{t("SideBar.Profile")}</Link>, '/user', <UserOutlined />),
      getItem(<Link onClick={logout}>{t("SideBar.Logout")}</Link>, 'logout', <PoweroffOutlined />),
    ];
    return (
      <Layout className='SidebarLayout'>
      {isDesktop&&<Sider
      trigger={null}  
        collapsedWidth="0"
        width="180px"
        style={{
          height: '100vh',
          position: 'relative',
          left: 0,
          top: 0,
          bottom: 0,
          background: colorBgContainer,
          textAlign: 'initial',
        }}
      >
              <div
          style={{
            height: 32,
            margin: 16,
            textAlign:"center",
          }}
        ><Title level={5}>DeviceSpeak Fleet</Title></div>
          
          {((role!=="Super Admin"&&allworkspaces.length>0)||(role==="Admin"))&&<Dropdown
            menu={{
              items: fullworkspaces && [(role==="Admin"||allworkspaces?.length>1)&&{
                key: "1",
                label: (<div onClick={() => {
                  if (role === "Admin") {
                    setnameworckspace(companyname);
                    localStorage.setItem("nameworkspace", companyname);
                  } else {
                    setnameworckspace("Allspace");
                    localStorage.setItem("nameworkspace", "Allspace");
                  }
                    localStorage.setItem("worckID", JSON.stringify([]));
                    handleUpdateAuthUser("all");
                    setOpen1(false);
                  }}>
                    <Row style={{ width:"20vw" }}>
                      <Col span={22}>
                        <div style={{ display: "flex" }}>
                          <Avatar shape="square" icon={role === "Admin" ? companyname[0]:t("All")[0]} size="default" style={{ flex: "none" }} />
                          <div style={{ flex: "1", paddingLeft: "8px" }}>
                            <Row>{role==="Admin"?companyname:t("Allspace")}</Row>
                            <Row>
                              {role==="Admin"?<Text type='secondary' style={{ fontSize: "12px" }}>
                                {`${t("Allspace")} • ${t("All")} ${t("materials")}`}
                              </Text>:
                              <Text type='secondary' style={{ fontSize: "12px" }}>
                                {`${t("Workspace")} • ${t("All")} ${t("materials")}`}
                              </Text>}
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col span={2}>
                      <div className={"WorkspaceItem"}>
                     { (localStorage.getItem("worckID")==="[]" ) ? <CheckOutlined />:""}
                      </div>
                      </Col>
                    </Row>
                  </div>)
              },...fullworkspaces.map((ele) => {
                return {
                  key: ele._id,
                  label: (<div onClick={() => {
                    setnameworckspace(ele.name);
                    localStorage.setItem("nameworkspace", ele.name);
                    localStorage.setItem("worckID", ele._id);
                    handleUpdateAuthUser(ele._id);
                    setOpen1(false);
                  }}>
                    <Row style={{ width:"20vw" }}>
                      <Col span={22}>
                        <div style={{ display: "flex" }}>
                          <Avatar shape="square" icon={ele.name[0]} size="default" style={{ flex: "none" }} />
                          <div style={{ flex: "1", paddingLeft: "8px" }}>
                            <Row>{ele.name}</Row>
                            <Row>
                              <Text type='secondary' style={{ fontSize: "12px" }}>
                                {`${t("Workspace")} • ${ele.vehicle.length} ${t("materials")}`}
                              </Text>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col span={2}>
                      <div className={"WorkspaceItem"}>
                     { (localStorage.getItem("worckID")===ele._id ) ? <CheckOutlined />:""}
                      </div>
                      </Col>
                    </Row>
                  </div>)
                }
              }),]
            }}
            trigger={['click']}
            placement='bottomLeft'
            onOpenChange={handleOpenChange}
            open={open1}
            
          >
            <Button style={{ height:40 }} type="text" block>
          <div className="WorkspacesDiv WorkspacesIcon">
            <Space>
            {t(nameworckspace)}
             { open1 ? <UpOutlined /> : <DownOutlined />}
            </Space>
          </div>
            </Button>

        </Dropdown>}
        <Menu  defaultSelectedKeys={[sampleLocation.pathname]} inlineCollapsed={false} mode="inline"  items={items}  />
        
      </Sider>}
      <Layout
      style={{
        height: '100vh',
        backgroundColor: '#f6f9fc',
      }}>
         <Outlet />
         {children}
         <Footer
            style={{
              position: 'fixed',
              zIndex:1000,
              bottom: 0,
              width: '100%',
              display: isDesktop ? 'none' : 'flex',
              flexDirection: 'column',
            }}
          >
        <TabBar activeKey={sampleLocation.pathname} onChange={(key)=>{
            if (key==='autre') {
              setOpen(true);
            }
        }} >
          {tabs.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
        </TabBar>
        <Modal
          visible={open}
          closeOnMaskClick={true}
          closeOnAction={true}
          onClose={() => {
            setOpen(false)
          }}
          content={
            <>
            <TabBar activeKey={null} onChange={()=>{setOpen(false)}} style={{margin:'10px 0 20px 0'}}>
              {tabs2.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
            </TabBar>
            <TabBar activeKey={null} onChange={()=>{setOpen(false)}} style={{margin:'20px 0 10px 0'}}>
              {tabs3.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
            </TabBar>
            </>
          }
        />
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Dashbord;
