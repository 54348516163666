/* eslint-disable array-callback-return */
import { React, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup, LayersControl } from "react-leaflet";
import { Descriptions, Layout, Modal } from "antd";
import { Typography, Card, Row, Col } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_GEOFENCE } from "../../queries/alertQuery.js";
import { DELETE_NOTIFICATION } from "../../mutations/notificationMutation.js";
import Loading from "../../components/Loading/Loading.js";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/Alerts.css";
import { convertToDaysHoursMins, convertToHoursMins } from "../../constants/ConvertHora.js";
import { Button } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_NOTIFICATION } from "../../mutations/notificationMutation.js";
import { GET_NOTIFICATIONS, GET_NOTREEDNOTIFICATIONS } from "../../queries/notificationQuery.js";
import { ValueContext } from "../../context/Context.js";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const { Content } = Layout;
const { Text } = Typography;

function NotificationsDetailsMobile(props) {
  const { t } = useTranslation();
  const { value, setValue } = useContext(ValueContext);
  const { company, role, _id } = props.props;
  const navigate = useNavigate();
  let location = useLocation();
  const notification = location.state.notification;
  
  const [deletenotification] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: [
      { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
      { query: GET_NOTREEDNOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
    ],
  });

  const [updatenotification] = useMutation(UPDATE_NOTIFICATION, {
    refetchQueries: [
      { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
      { query: GET_NOTREEDNOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
    ],
  });

  const [getGeofence, { data, loading }] = useLazyQuery(GET_GEOFENCE);

  useEffect(() => {
    if (notification.alert.type === "Geofence") {
      getGeofence({ variables: { _id: notification.alert.geofence._id } });
    }
    if (notification.status === "Not Read") {
      updatenotification({ variables: { _id: notification._id } });
      if (value > 0) {
        setValue(value - 1);
      }
    }
  }, [
    getGeofence,
    notification._id,
    notification.alert.geofence._id,
    notification.alert.type,
    notification.status,
    setValue,
    updatenotification,
    value
  ]);

  const showDeleteConfirm = (_id, status) => {
    confirm({
      title: t("FormValidations.31"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.32"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      async onOk() {
        await deletenotification({
          variables: { _id, role },
        });
        if (value > 0 && status === "Not Read") {
          setValue(value - 1);
        }
        navigate(-1);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Content className="AlertDetailsContent">
      <Button className="RHistoryBtn3" size="mini" shape="rounded" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Card
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "650px",
          margin: "auto",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Descriptions labelStyle={{ color: "black" }} title={t("Description")} column={1}>
            <Row>
              <Col span={6}><Text type="danger">{t("Alerts.32")}</Text></Col>
              <Col>
                <Text type="danger">
                  {notification.typeAlert === "Activity" &&
                    t("Activity") +
                      " " +
                      (notification.name.includes("Km")
                        ? notification.name
                        : convertToHoursMins(parseInt(notification.name)))}
                  {notification.typeAlert === "Inactivity" &&
                    t("Inactivity") + " " + convertToDaysHoursMins(parseInt(notification.name))}
                  {notification.typeAlert === "Geofence" &&
                    notification.name === "Entered" && t("In")}
                  {notification.typeAlert === "Geofence" &&
                    notification.name === "Leaves" && t("Out")}
                  {notification.typeAlert === "FuelLevel" && t(notification.name)}
                  {notification.typeAlert === "speed" &&
                    t("Limite de vitesse dépassée :  ") + notification.name + " Km/h "}
                  {notification.typeAlert === "Idle" &&
                    t("Limite de ralenti dépassée :  ") +
                      convertToHoursMins(notification.name / 60)}
                  {notification.typeAlert === "power" &&
                    notification.name === "power off" && t("L'appareil a été débranché")}
                  {notification.typeAlert === "power" &&
                    notification.name === "power on" && t("L'appareil a été branché")}
                  {notification.typeAlert === "crush" && t("Détection d'accident")}
                  {notification.typeAlert === "towing" && t("Détection de remorquage")}
                </Text>
              </Col>
            </Row>
            {notification.typeAlert === "speed" && (
              <Row>
                <Col span={6}>{t("speed2")}</Col>
                <Col>{notification.speed.toFixed(0) + " Km/h"}</Col>
              </Row>
            )}
            <Row>
              <Col span={6}>{t("Alerts.31")}</Col>
              <Col>{notification.nameAlert}</Col>
            </Row>
            {notification.typeAlert === "Niveau de carburant " && (
              <>
                <Row>
                  <Col span={6}>{t("Niveau de carb (avant)")}</Col>
                  <Col>{"400 L"}</Col>
                </Row>
                <Row>
                  <Col span={6}>{t("Niveau de carb (après)")}</Col>
                  <Col>{"390 L"}</Col>
                </Row>
                <Row>
                  <Col span={6}>{t("Qté Consommé")}</Col>
                  <Col>{"10 L"}</Col>
                </Row>
                <Row>
                  <Col span={6}>{t("Position")}</Col>
                  <Col>{"نهج محمد بدرة, ميدون, آركو, معتمدية جربة ميدون, ولاية مدنين, 4165, تونس"}</Col>
                </Row>
                <Row>
                  <Col span={6}>{t("Espace de travail")}</Col>
                  <Col>{"Djerba"}</Col>
                </Row>
              </>
            )}
            <Row>
              <Col span={6}>{t("InventoryTable.Marque")}</Col>
              <Col>{notification.marque}</Col>
            </Row>
            <Row>
              <Col span={6}>{t("InventoryTable.Model")}</Col>
              <Col>{notification.model}</Col>
            </Row>
            <Row>
              <Col span={6}>{t("InventoryTable.Category")}</Col>
              <Col>{notification.categorie}</Col>
            </Row>
            <Row>
              <Col span={6}>Source</Col>
              <Col>{notification.source}</Col>
            </Row>
            <Row>
              <Col span={6}>Date</Col>
              <Col>
                {dayjs(Math.floor(notification.createdAt / 1000) * 1000).format("DD MMM YYYY HH:mm")}
              </Col>
            </Row>
            {data && notification.alert.geofence && (
              <>
                <Row>
                  <Col span={24}>Zone</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <MapContainer
                      scrollWheelZoom={true}
                      style={{ height: "250px", width: "100%" }}
                      bounds={data.getGeofence.coordinates}
                    >
                      <LayersControl position="topright">
                        <LayerGroup>
                          <LayersControl.BaseLayer checked name="Simple">
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Satellite">
                            <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Google Hybrid">
                                                       <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
                          </LayersControl.BaseLayer>
                        </LayerGroup>
                        <Polygon
                          pathOptions={{ color: "red" }}
                          positions={data.getGeofence.coordinates}
                        />
                      </LayersControl>
                    </MapContainer>
                  </Col>
                </Row>
              </>
            )}
          </Descriptions>
        )}
      </Card>
      <Row justify="center" style={{ marginTop: "15px" }}>
        <Button
          className="button"
          size="mini"
          color="danger"
          shape="rounded"
          onClick={() => showDeleteConfirm(notification._id, notification.status)}
        >
          {t("Delete")}
        </Button>
      </Row>
    </Content>
  );
}

export default NotificationsDetailsMobile;
