/* eslint-disable array-callback-return */
import { React, useState, useEffect,useContext } from "react";
import { ValueContext } from "../../context/Context.js";
import { useApolloClient } from '@apollo/client';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Tooltip,
  Switch,
  Space,
  Modal,
  notification,Row,Col,
  Select
} from "antd";
import { useQuery , useMutation } from "@apollo/client";
import { GET_VEHICLES } from "../../queries/mapQuery.js";
import { GET_IDLE_REPORTS } from "../../queries/Idle_reportQuery.js";
import { GET_RALENTIESTATISTICSFORCOMPANY ,GET_RALENTIESTATISTICSFORCHARTS} from "../../queries/utilisationQuery.js";
import {
  SearchOutlined,
  InsertRowLeftOutlined,
  ExclamationCircleFilled,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import "../../css/Inventory.css";
import dayjs from "dayjs";
import Loading from "../../components/Loading/Loading.js";
import { convertToHoursMins ,convertSecondsToHoursMins } from "../../constants/ConvertHora.js";
import CreateAssetForm from "../../components/Forms/CreateAssetForm.js";
import { DELETE_ASSET } from "../../mutations/assetMutations.js";
import { Bar } from "react-chartjs-2";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function IdelReports(props) {
  const { t } = useTranslation();
  const client = useApolloClient();  
  const { isDesktop } = useContext(ValueContext);
  const [IdelcheckedColumns, setIdelcheckedColumns] = useState(
    JSON.parse(localStorage.getItem("IdelcheckedColumns")) || []
  );
  const Displayedcolumns = [
    {
      title: t("InventoryTable.SerialNumber"),
        dataIndex: "serialNumber",
        width: 'max-content',
      },
    {
      title: t("InventoryTable.Model"),
      dataIndex: "model",
      width: 'max-content',
    },
    {
      title: t("Espace de travail"),
      dataIndex: "ET",
      width: 'max-content',
    },
    {
        title: t("Idle.1"),
        dataIndex: "start",
        width: 'max-content',
    },
    {
        title: t("Idle.2"),
        dataIndex: "end",
        width: 'max-content',
    },
    {
        title: t("Idle.3"),
        dataIndex: "duration",
    },
    {
        title: t("Idle.4"),
        dataIndex: "FU",
    },
    {
      title: t("Position"),
      dataIndex: "position",
    },
];
  const columns = [
    {
      title: t("InventoryTable.SerialNumber"),
        dataIndex: "serialNumber",
        width: 'max-content',
      },
    {
      title: t("InventoryTable.Model"),
      dataIndex: "model",
      width: 'max-content',
    },
    {
      title: t("Espace de travail"),
      dataIndex: "ET",
      width: 'max-content',
    },
    {
        title: t("Idle.1"),
        dataIndex: "start",
        width: 'max-content',
    },
    {
        title: t("Idle.2"),
        dataIndex: "end",
        width: 'max-content',
    },
    {
        title: t("Idle.3"),
        dataIndex: "duration",
    },
    {
        title: t("Idle.4"),
        dataIndex: "FU",
    },
    {
      title: t("Position"),
      dataIndex: "position",
    },
].filter((col) =>
IdelcheckedColumns.every(
    (y) => !col.dataIndex.toLowerCase().includes(y.toLowerCase())
    )
  );
  const columns2 = [
    {
      title: t("InventoryTable.SerialNumber"),
        dataIndex: "serialNumber",
        width: 'max-content',
      },
    {
        title: t("Idle.6"),
        dataIndex: "idel",
    },
    {
        title: t("Idle.7"),
        dataIndex: "prod",
    },
    {
        title: t("Idle.5"),
        dataIndex: "duration",
    },
];
  const [searchInput, setSearchInput] = useState("");
  const [period, setperiod] = useState("ToDay");
  const { company, role, workspaces } = props.props;
  const { loading, error, data } = useQuery(GET_IDLE_REPORTS, {
    variables: { _id: company, role: role, workspace: workspaces ,period:period },fetchPolicy: "no-cache",
  });
  const { loading:loading2, error:error2, data:data2 } = useQuery(GET_RALENTIESTATISTICSFORCOMPANY, {
    variables: { company:company,period:period},fetchPolicy: "no-cache",
  });
  const { loading:loading3, error:error3, data:data3 } = useQuery(GET_RALENTIESTATISTICSFORCHARTS, {
    variables: { company:company,period:period},fetchPolicy: "no-cache",
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.11"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("Maintenance.26"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.27"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteAsset({ variables: { _id,role } });
      },
    });
  };
  const [deleteAsset] = useMutation(DELETE_ASSET, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(company+'updateVehicle', (updatedVehicle) => {
        const updatedVehicles = data.getVehicles&&data.getVehicles.map((vehicle) =>
        vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      );
        // update the data in the Apollo cache
        client.writeQuery({
          query: GET_VEHICLES,
          variables: { _id: company, role: role, workspace: workspaces },
          data: { getVehicles: updatedVehicles },
        });
      });
    }
    return () => {
      if (socket) {
        socket.off(company+'updateVehicle');
      }
    };
  }, [socket, data, client, company, role, workspaces]);

  if (loading) return <Loading />;
  if (error) return <></>;
  if (loading2) return <Loading />;
  if (error2) return <></>;
  if (loading3) return <Loading />;
  if (error3) return <></>;
  

  
  let ReportData = [];

    const TableData = data.getIdle_reports.filter((vehicle) => {
      if (searchInput === "") {
        return vehicle;
      } else if (vehicle.Model.toLowerCase().includes(searchInput.toLowerCase())) {
        return vehicle;
      }
    });
    ReportData = TableData.map((report) => {
      if (report.duration >= 60) {
        return {
          key: report._id,
          start: dayjs(Math.floor(report.createdAt / 1000) * 1000).format(
            "DD MMM YYYY HH:mm"
          ),
          end: report.conso?dayjs(Math.floor(report.updatedAt / 1000) * 1000).format(
            "DD MMM YYYY HH:mm"
          ):t("encours"),
          serialNumber: report.serialNumber,
          model: report.Model,
          ET: report.workspace ? report.workspace.name:"-",
          position: report.address,
          duration: convertSecondsToHoursMins(report.duration),
          FU: report.conso?parseFloat(report.conso).toFixed(2) + " L":"-",
        };
      } else {
        return null;
      }
    }).filter((item) => item !== null);
  const  ReportData2 = data2.getRalentieStatisticsForCompany.map((report) => {
        return {
          key: report._id,
          serialNumber: report.profileInfo.serialNumber,
          idel:convertToHoursMins(report.totalRalentie/60),
          prod:convertToHoursMins(report.totalProd/60),
          duration: report.ralentiePercentage+" %",
        };

    });
  
  const onChange = (checked, value) => {
    if (checked) {
      let x = IdelcheckedColumns.filter((x) => x !== value);
      setIdelcheckedColumns(x);
      localStorage.setItem("IdelcheckedColumns", JSON.stringify(x));
    } else {
      setIdelcheckedColumns([...IdelcheckedColumns, value]);
      localStorage.setItem(
        "IdelcheckedColumns",
        JSON.stringify([...IdelcheckedColumns, value])
      );
    }
  };
  const periodoptions = [
    {
      value: "ToDay",
      label: t("Alerts.23"),
    },
    {
      value: "YesterDay",
      label: t("Alerts.24"),
    },
    {
      value: "Last 7 Day",
      label: t("Alerts.18"),
    },
    {
      value: "Last 15 Day",
      label: t("Alerts.19"),
    },
    {
      value: "Last Month",
      label: t("Alerts.20"),
    },
  ];
  let chartData = {
    labels: data3.getRalentieStatisticsForCharts?.map((item) => item.date).reverse(),
    datasets: [
      {
        label: t("TauR")+" (%)",
       data: data3.getRalentieStatisticsForCharts?.map((item,i) => {
         return item.ralentiePercentage;
       }).reverse(),
       backgroundColor: "#95BDFF",
       borderColor: "#95BDFF",
       barPercentage: 0.2,
     },
   ],
};
  const options = {
    scales: {
      y: {
        min:0,
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = " "+context.parsed.y+" %";
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
  return (
      <>
    <Content className="IdelReportsContent">
      <Row style={{ marginBottom:10 }}>
      <div className="ButtonsDiv"><Select  style={{ width:144,textAlign:"initial" }} value={period}
                      options={periodoptions}
                      onChange={(e) => {
                        setperiod(e);
                      }}/></div>
      </Row>
      <Row gutter={[16,16]} className="Brow" style={{marginBottom:20}}  >
        <Col className="SrowCol"  sm={24} md={16}>


        <Row className="Srow1" >
          <Bar data={chartData} className="barCharts" options={options} />
        </Row></Col>
        <Col  sm={24} md={8}>
        <Table
        columns={columns2}
        size="small"
        className="idelReportTabel"
        dataSource={ReportData2}
        title={() => (
          <>
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="IdelSearchBar"
                placeholder={t("SearchInput")+" ("+ReportData2.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              /></Col>
                  </Row>  
                </>
        )}
        pagination={false}
        />

        </Col>
      </Row>
      <Table
        columns={role !== "Super Admin" ? (columns.filter(option=>option.title!==t("company"))):columns}
        size="small"
        dataSource={ReportData}
        title={() => (
          <>
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="InventorySearchBar"
                placeholder={t("SearchInput")+" ("+ReportData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              /></Col>
              <Col span={12}>              
              <div className="ButtonsDiv">
                  <Tooltip
                    color={"white"}
                    trigger={"click"}
                    placement="bottomRight"
                    title={
                      <div>
                      {
                        Displayedcolumns.map((column)=>{
                          return(<div>
                          <Space size={"small"}>
                            <Switch
                              size="small"
                              value={column.dataIndex}
                              checked={
                                !IdelcheckedColumns.some(
                                  (code) => code === column.dataIndex
                                )
                              }
                              onChange={(checked) =>
                                onChange(checked, column.dataIndex)
                              }
                            />
                            <Text>{column.title}</Text>
                          </Space>
                        </div>)
                        })
                      }
                      </div>
                    }
                  >
                    <Button style={{ marginRight:'5px' }}>
                    {isDesktop?<div><InsertRowLeftOutlined style={{ marginRight:10 }}/>{t("InventoryTable.Columns")}</div>:<div><InsertRowLeftOutlined /></div>}
                    </Button>
                  </Tooltip>
                  <Button style={{ marginRight:'5px' }}>
                    {isDesktop?<div><VerticalAlignBottomOutlined style={{ marginRight:10 }} />{t("Export")}</div>:<div><InsertRowLeftOutlined /></div>}
                    </Button>
                  </div></Col>
                  </Row>
                </>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
      </Content>
    </>
    
  );
}
export default IdelReports;
