/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup,LayersControl } from "react-leaflet";
import {
  Button,
  Modal,
  Descriptions,
  Col,
  Row,
  Typography,
} from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_GEOFENCE } from "../../queries/alertQuery.js";
import { GET_NOTIFICATIONS } from "../../queries/notificationQuery.js";
import { UPDATE_NOTIFICATION } from "../../mutations/notificationMutation.js";
import { ValueContext} from "../../context/Context.js";
import dayjs from "dayjs";
import Loading from "../Loading/Loading.js";
import { convertToHoursMins,convertToDaysHoursMins } from "../../constants/ConvertHora.js";
const { Text } = Typography;
function NotificationDetails(props) {
  const { t } = useTranslation();
  const { notification } = props;
  const { company, role, _id } = props.props;
  const { value, setValue } = useContext(ValueContext);
  const [open, setOpen] = useState(false);
  const [updatenotification] = useMutation(UPDATE_NOTIFICATION,{
    refetchQueries: [
      { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const showModal = async () => {
    setOpen(true);
    if(notification.alert.type==="Geofence"){
        await getGeofence({ variables: { _id:notification.alert.geofence._id } });
      }
    if (notification.status==="Not Read") {
       updatenotification({ variables: { _id: notification._id } });
       if (value > 0) {
         setValue(value - 1);
       }
    }
  };
  const [getGeofence,{data,loading}]= useLazyQuery(GET_GEOFENCE);
  
  return (
    <>
      <Button
        onClick={showModal}
      >
        {t("Details")}
      </Button>
      <Modal
        style={{
          top: 20,
        }}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {loading  ? <Loading/>:<Descriptions labelStyle={{ color:"black" }} title={t("Description")} column={1}>
        <Row>
            <Col span={6}><Text type="danger">{t("Alerts.32")}</Text></Col>
            <Col>            
            <Text type="danger">
        {notification.typeAlert==="Activity"&&t("Activity")+" "+(notification.name.includes("Km") ? notification.name:convertToHoursMins(parseInt(notification.name)))}
        {notification.typeAlert==="Inactivity"&&t("Inactivity")+" "+convertToDaysHoursMins(parseInt(notification.name))}
        {notification.typeAlert==="Geofence"&&(notification.name==="Entered")&&t("In")}
        {notification.typeAlert==="Geofence"&&(notification.name==="Leaves")&&t("Out")}
        {notification.typeAlert==="FuelLevel"&&t(notification.name)}
        {notification.typeAlert==="speed"&&t("limite de vitesse  ")+notification.name+" Km/h "}
        {notification.typeAlert==="Idle"&&t("Limite de ralenti dépassée :  ")+convertToHoursMins(notification.name/60)}
        {notification.typeAlert==="power"&&t("Véhicule débranché")}
        {notification.typeAlert==="crush"&&t("Il y a possibilité d'accident")}
        {notification.typeAlert==="towing"&&t("Il y a détection de remorquage")}
            </Text>
            </Col>
        </Row>
        {notification.typeAlert==="speed"&&(<Row>
            <Col span={6}>{t("speed2")}</Col>
            <Col>{notification.speed.toFixed(0)+" Km/h"}</Col>
        </Row>)}
        <Row>
            <Col span={6}>{t("Alerts.31")}</Col>
            <Col>{notification.nameAlert}</Col>
        </Row>
        {notification.typeAlert==="Niveau de carburant "&&(
          <>
          <Row>
            <Col span={6}>{t("Niveau de carb (avant)")}</Col>
            <Col>{"400 L"}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("Niveau de carb (après)")}</Col>
            <Col>{"390 L"}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("Qté Consommé")}</Col>
            <Col>{"10 L"}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("Position")}</Col>
            <Col>{"نهج محمد بدرة, ميدون, آركو, معتمدية جربة ميدون, ولاية مدنين, 4165, تونس"}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("Espace de travail")}</Col>
            <Col>{"Djerba"}</Col>
        </Row>
          </>
        )}
        <Row>
            <Col span={6}>{t("InventoryTable.Marque")}</Col>
            <Col>{notification.marque}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("InventoryTable.Model")}</Col>
            <Col>{notification.model}</Col>
        </Row>
        <Row>
            <Col span={6}>{t("InventoryTable.Category")}</Col>
            <Col>{notification.categorie}</Col>
        </Row>
        <Row>
            <Col span={6}>Source</Col>
            <Col>{notification.source}</Col>
        </Row>
        <Row>
            <Col span={6}>Date</Col>
            <Col>{dayjs(Math.floor(notification.createdAt / 1000) * 1000).format(
                  "DD MMM YYYY HH:mm"
                )}</Col>
        </Row>
        {notification.alert.geofence&&data&&(
          <>
          <Row><Col span={24}>Zone</Col></Row>
          <Row>
            
            <Col span={24}>
            <MapContainer
              scrollWheelZoom={true}
              style={{ height: "250px", width: "100%" }}
              bounds={data.getGeofence.coordinates}
            >
              <LayersControl position="topright">
                <LayerGroup>
                  <LayersControl.BaseLayer checked name="Simple">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
                </LayerGroup>
              </LayersControl>
              <Polygon positions={data.getGeofence.coordinates} />
            </MapContainer>
            </Col>
        </Row></>)}
        </Descriptions>}
      </Modal>
    </>
  );
}
export default NotificationDetails;
