/* eslint-disable array-callback-return */
import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_ASSET } from "../../../../mutations/assetMutations.js";
import { GET_CATEGORIES } from "../../../../queries/categorieQuery.js";
import { GET_MARQUES } from "../../../../queries/marqueQuery.js";
import { Input, Button, Form, Space, Select, notification, Row, Col } from "antd";
import { Button as MobileBtn,Modal } from "antd-mobile";
import { useMutation, useLazyQuery,useQuery } from "@apollo/client";
import { GET_VEHICLES } from "../../../../queries/mapQuery.js";
import { GET_COMPANYS } from "../../../../queries/companyQuery.js";
import Loading from "../../../../components/Loading/Loading.js";
import { useNavigate } from "react-router";
import { Content } from "antd/es/layout/layout.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBarcode, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Html5QrcodeScanner } from "html5-qrcode";
function CreateAssetMobile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showQR, setshowQR] = useState(false);
  const [qrCodeScanner, setQrCodeScanner] = useState(null);
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    // notification.open({
    //   description: t("Notification.1"),
    //   duration:2,
    //   closeIcon: false,
    //   className:'CustomSuccess'
    // });
    window.location.reload();
  };
  const [companystatus, setcompanystatus] = useState("");
  const { company,companyname, role, workspaces, _id, fullworkspaces } = props.props;
  const [comp, setcomp] = useState("");
  const [form] = Form.useForm();
   const [usage, setusage] = useState("hr");
   const { loading:loadingC, data:dataC } = useQuery(GET_CATEGORIES, {
     variables: { company: company, role: role },
   });
   const { loading:loadingM, data:dataM } = useQuery(GET_MARQUES, {
     variables: { company: company, role: role },
   });
   const [getCompanys, { loading, data }] = useLazyQuery(GET_COMPANYS,{
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [createAsset] = useMutation(CREATE_ASSET, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
    useEffect(() => {
        getCompanys({ variables: { role: role } });
    }, [getCompanys, role]);
  const usageoptions = [
     {
       value: "hr",
       label: t("hr"),
     },
     {
       value: "km",
       label: t("km"),
     },
   ];
   const workspaceoptions = [];
    fullworkspaces &&
      fullworkspaces.map((ele) => {
        if (workspaces && workspaces === ele._id) {
          workspaceoptions.push({
            value: ele._id,
            label: ele.name,
          });
         } else if(workspaces?.length<1) {
          workspaceoptions.push({
            value: ele._id,
            label: ele.name,
          });
         
        }
      });
  const companyoptions = [];
  role === "Super Admin" &&
    data &&
    data.getCompanys.map((ele) => {
      companyoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
  const catoptions = [];
  dataC &&
    dataC.getCategories.map((ele) => {
      catoptions.push({
        value: ele.name,
        label: ele.name,
      });
    });
  const marqueoptions = [];
  dataM &&
    dataM.getMarques.map((ele) => {
      marqueoptions.push({
        value: ele.name,
        label: ele.name,
      });
    });

    const handleScan = () => {
          setshowQR(true);
          const scanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: { width: 250, height: 250 } },
            false
          );
      
          scanner.render(
            (decodedText) => {
              form.setFieldValue("IMEI",decodedText);
              setshowQR(false);
              scanner.clear();
            },
            (error) => {
              console.warn(error);
            }
          );
          setQrCodeScanner(scanner);
        };
      
        const handleCancel = () => {
          if (qrCodeScanner) {
            qrCodeScanner.clear();
          }
          setshowQR(false);
        };
      
  return (
    <>
    <Content className="GeoContent">
    <MobileBtn className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </MobileBtn>
      {loading  ||loadingC || loadingM ? <Loading/>:(<Form
          form={form}
          style={{marginBottom:100}}
          onFinish={()=>{
            form
            .validateFields()
            .then((values) => {
              if (comp === "" && role === "Super Admin") {
                setcompanystatus("error");
              } else {
                createAsset({
                  variables: {
                    serialNumber: values.serialNumber,
                    Model: values.Model,
                    Marque: values.Marque,
                    Categorie: values.Categorie,
                    hourMeter: parseInt(values.hourMeter),
                    odometer: parseInt(values.odometer),
                    Fuel: parseInt(values.fuel),
                    Source: values.Source,
                    usage: usage,
                    assetID: values.IMEI,
                    workspace: values.workspace ? (values.workspace==="All"? null: values.workspace):null,
                    company: comp === "" ? company : comp,
                    createdBy: _id,
                  },
                });
              }
              navigate("/inventory")
              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
          }}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            workspace:workspaces,
          }}
        >
          <Form.Item
            name="serialNumber"
            label={t("InventoryTable.SerialNumber")}
            rules={[
              {
                required: true,
                message: t("FormValidations.1"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Marque"
            label={t("InventoryTable.Marque")}
            rules={[
              {
                required: true,
                message: t("FormValidations.2"),
              },
            ]}
          >
            <Select
                  style={{
                    width: "100%",
                  }}
                  options={marqueoptions}
                />
          </Form.Item>
          <Form.Item
            name="Model"
            label={t("InventoryTable.Model")}
            rules={[
              {
                required: true,
                message: t("FormValidations.3"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Categorie"
            label={t("InventoryTable.Category")}
            rules={[
              {
                required: true,
                message: t("FormValidations.4"),
              },
            ]}
          >
            <Select
                  style={{
                    width: "100%",
                  }}
                  options={catoptions}
                />
          </Form.Item>
          <Form.Item
            name="hourMeter"
            label={t("InventoryTable.Horametre")}
            initialValue={0}
            // rules={[
            //   {
            //     required: true,
            //     message: t("FormValidations.5"),
            //   },
            // ]}
          >
            <Input type="number" addonAfter={t("FormValidations.10")} pattern="^[0-9]+$"

 />
          </Form.Item>
          <Form.Item
            name="odometer"
            label={t("InventoryTable.Odometre")}
            initialValue={0}
          >
            <Input type="number" addonAfter={"Km"} />
          </Form.Item>
          <Form.Item
            name="fuel"
            label={t("Fuelconsumption")}
            initialValue={0}
          >
            <Input type="number" addonAfter={<Select
                  value={usage}
                  options={usageoptions}
                  onChange={(e) => {
                    setusage(e);
                  }}
                />} />
          </Form.Item>
          {role === "Super Admin" && (
            <>
              <p>Company</p>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                }}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={companyoptions}
                  status={companystatus}
                  onChange={(e) => {
                    setcomp(e);
                  }}
                />
              </Space>
            </>
          )}
          <Form.Item
            name="Source"
            label="Source"
            rules={[
              {
                required: true,
                message: t("FormValidations.6"),
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
              options={[{value: "DS1", label:"DS1"},
              {value: "Teltonika FMB140", label:"Teltonika FMB140"},
              {value: "Teltonika FMB920", label:"Teltonika FMB920"},]}
            />
          </Form.Item>
          <Row>
            <Space.Compact style={{width:"100%"}}>
            <Col span={20}>
          <Form.Item
            name="IMEI"
            label="IMEI"
            // rules={[
            //   {
            //     required: true,
            //     message: t("FormValidations.7"),
            //   },
            // ]}
          >
            <Input />
          </Form.Item></Col>
          <Col span={4} style={{alignContent:'end'}}><Button onClick={handleScan}><FontAwesomeIcon icon={faBarcode} /></Button></Col>
            </Space.Compact>
            
            </Row>
          {role !== "Super Admin" && (
              <Form.Item
                name="workspace"
                label={t("Workspace")}
                rules={[
              {
                required: true,
                message: t("FormValidations.49"),
              },
            ]}
              >
                <Select
                  style={{
                      width: "100%",
                    }}
                  options={role !== "Admin" ? workspaceoptions : [{value: "All", label: companyname},...workspaceoptions]}
                />
              </Form.Item>
            )}
                      <div style={{ marginTop: 20 }}>
            <Space direction="horizontal">
              <Link to={{ pathname: "/inventory" }}>
                <Button>{t("Alerts.9")}</Button>
              </Link>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("Alerts.10")}
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Form>)}
        </Content>
        <div style={{
          position:"absolute",top:0,left:0,zIndex:2000,width:"100vw",height:"100vh",display:showQR ? "block":"none"
        }}>
          <MobileBtn className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={handleCancel} >
          <FontAwesomeIcon icon={faXmark} />
          </MobileBtn>
        <div id="reader" style={{ width: '100%',height:"100%",backgroundColor:"white" }}></div>
        </div>
    </>
  );
}
export default CreateAssetMobile;
