import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import axios from 'axios';
import * as actions from '../../constants/action_types.js';

// Update Axios to use environment variable
async function authenticate(dispatch) {
    const token = localStorage.getItem('token');
    if (!!token) {
        try {
            const requestBody = {
              query: `
                   query {
                       verifyToken(token: "${token}") {
                           _id
                           company{
                            _id
                            name
                           }
                           email
                           role{
                            name
                           }
                           workspace{
                            _id
                            name
                            vehicle{
                                _id
                            }
                           }
                       }
                   }
                `,
            };

            // Use environment variable for backend URL
            const { data } = await axios.post(
                "/graphql", // Dynamically set the backend URL
                requestBody
            );

            const user = await data.data.verifyToken;
            const workspace = user.workspace.map((ele) => ele._id);
            if (user) {
                dispatch({
                  type: actions.SET_AUTH_USER,
                  authUser: Object.freeze({
                    _id: user._id,
                    email: user.email,
                    role: user.role.name,
                    company: user.company ? user.company._id : null,
                    companyname: user?.company?.name ? user.company.name : null,
                    workspaces:
                      localStorage.getItem("worckID") === "[]"
                        ? user.role.name === "Admin"
                          ? JSON.parse(localStorage.getItem("worckID"))
                          : workspace
                        : localStorage.getItem("worckID"),
                    fullworkspaces: user.workspace,
                    allworkspaces: workspace,
                  }),
                });
            } else {
                dispatch({ type: actions.SET_AUTH_USER, authUser: null });
                localStorage.removeItem('token');
            }
        } catch {
            console.log("user");
            dispatch({ type: actions.SET_AUTH_USER, authUser: null });
        }
    } else {
        dispatch({ type: actions.SET_AUTH_USER, authUser: null });
    }
}

function useWithAuthenticate() {
    const dispatch = useDispatch();
    useEffect(() => {
        authenticate(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export default useWithAuthenticate;
