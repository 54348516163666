/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Modal,
  Row,
  Col,
  Dropdown,
  notification,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COMPANYS } from "../../queries/companyQuery.js";
import {GET_USERS } from "../../queries/UserQuery.js";
import {GET_VEHICLES } from "../../queries/mapQuery.js";
import { DELETE_COMPANY } from "../../mutations/companyMutation.js";
import {
  SearchOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import Loading from "../../components/Loading/Loading.js";
import CreateCompanyForm from "../Forms/CreateCompanyForm.js";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
export default function CompanyTab(props) {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Administration.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Administration.description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];
  const [searchInput, setSearchInput] = useState("");
  const { role,company,workspaces } = props.props;

  const { loading, error, data } = useQuery(GET_COMPANYS, {
    variables: {role: role },
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.12"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [deletecompany] = useMutation(DELETE_COMPANY, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_COMPANYS,
        variables: { role: role },
      },
      {
        query: GET_USERS,
        variables: { _id: company, role: role, workspace: workspaces },
      },
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  if (loading) return <Loading />;
    if (error) return <></>;
    console.log(data);
  const showDeleteConfirm = (_id) => {
    console.log(_id);
    confirm({
      title: t("FormValidations.43"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.44"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deletecompany({ variables: { _id, role } });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const TableData = data.getCompanys.filter((company) => {
    if (searchInput === "") {
      return company;
    } else if (company.name.toLowerCase().includes(searchInput)) {
      return company;
    }
  });
  const MembersData = TableData.map((company) => {
    return {
      key: company._id,
      name: company.name,
      description:company.description.length > 50 ? company.description.slice(0, 50) + "...":company.description,
      action: (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      type="text"
                      block
                      onClick={() => {
                        showDeleteConfirm(company._id);
                      }}
                    >
                      <Text type="danger">{t("DeleteCompany")}</Text>
                    </Button>
                  ),
                  key: "0",
                },
              ],
            }}
            trigger={["click"]}
          >
            <Button type="text" icon={<EllipsisOutlined />}></Button>
          </Dropdown>
        </>
      ),
    };
  });
  return (
    <Content
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <Table
        columns={columns}
        size="small"
        dataSource={MembersData}
        title={() => (
          <Row>
          <Col span={12}>
              <Input
                size="default"
                placeholder={t("AlertsSearchInput")+" ("+MembersData.length+t("elements")+")"}
                className="SearchBar"
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                 <CreateCompanyForm props={props.props} />
                </div>
            </Col>
        </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
    </Content>
  );
}
