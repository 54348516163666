/* eslint-disable array-callback-return */
import { React, useState, useEffect,useContext } from "react";
import { ValueContext } from "../context/Context.js";
import { useApolloClient } from '@apollo/client';
import { useTranslation } from "react-i18next";
import { Link,useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import {
  Input,
  Typography,
  Table,
  Tag,
  Button,
  Layout,
  Tooltip,
  Switch,
  Space,
  Modal,
  Dropdown,
  notification,Row,Col,
  List,
  Card
} from "antd";
import { useQuery , useMutation } from "@apollo/client";
import { GET_VEHICLES } from "../queries/mapQuery.js";
import {
  EnvironmentOutlined,
  SearchOutlined,
  InsertRowLeftOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import "../css/Inventory.css";
import dayjs from "dayjs";
import Loading from "../components/Loading/Loading.js";
import { convertToHoursMins } from "../constants/ConvertHora.js";
import CreateAssetForm from "../components/Forms/CreateAssetForm.js";
import { DELETE_ASSET } from "../mutations/assetMutations.js";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function Inventory(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();  
  const { isDesktop } = useContext(ValueContext);
  const [InvcheckedColumns, setInvcheckedColumns] = useState(
    JSON.parse(localStorage.getItem("InvcheckedColumns")) || []
  );
 function Status(props) {
        let color = "";
        switch (props.status[0]) {
          case "Inactive":
            color = "volcano";
            break;
          case "Active":
            color = "green";
            break;
          default:
            break;
        }
        return (
          <>
         {props.status[0]!==""&&<div >
          <Tag color={color} key={props.status[0]}>
            {props.status[0]==="Active"?props.status[0].toUpperCase():props.status[1]!==null?(props.status[0].toUpperCase()+"/"+(props.status[1]==="1" ? "Contact allumé":"Contact éteint")):props.status[0].toUpperCase()}
          </Tag>
          {/* <div>{status[1]&&(status[0]==="Inactive")&&(status[1]==="1" ? <Text type="secondary">Contact allumé</Text>:<Text type="secondary">Contact éteint</Text>)}</div> */}
        </div>}
        </>
        );
      };
  const Displayedcolumns = [
    {
      title: t("InventoryTable.SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: t("InventoryTable.Model"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("InventoryTable.Category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("InventoryTable.Marque"),
      dataIndex: "marque",
      key: "marque",
    },
    {
      title: t("InventoryTable.Horametre"),
      dataIndex: "horametre",
      key: "horametre",
    },
    {
      title: t("InventoryTable.Odometre"),
      dataIndex: "odometer",
      key: "odometer",
    },
    {
      title: t("InventoryTable.Status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("InventoryTable.Position"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Workspace"),
      dataIndex: "workspace",
      key: "workspace",
    },
     {
      title: t("CreatedBy"),
      dataIndex: "createdby",
      key: "createdby",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const columns = [
    {
      title: t("InventoryTable.SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 'max-content',
    },
    {
      title: t("InventoryTable.Model"),
      dataIndex: "model",
      key: "model",
      width: 'max-content',
    },
    {
      title: t("InventoryTable.Category"),
      dataIndex: "category",
      key: "category",
      width: 'max-content',
    },
    {
      title: t("InventoryTable.Marque"),
      dataIndex: "marque",
      key: "marque",
      width: 'max-content',
    },
    {
      title: t("InventoryTable.Horametre"),
      dataIndex: "horametre",
      key: "horametre",
    },
    {
      title: t("InventoryTable.Odometre"),
      dataIndex: "odometer",
      key: "odometer",
    },
    {
      title: t("InventoryTable.Status"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "";
        switch (status[0]) {
          case "Inactive":
            color = "volcano";
            break;
          case "Active":
            color = "green";
            break;
          default:
            break;
        }
        return (
          <>
         {status[0]!==""&&<div style={{ textAlign: "center" }}>
          <Tag color={color} key={status[0]}>
            {status[0]==="Active"?status[0].toUpperCase():status[1]!==null?(status[0].toUpperCase()+"/"+(status[1]==="1" ? "Contact allumé":"Contact éteint")):status[0].toUpperCase()}
          </Tag>
          {/* <div>{status[1]&&(status[0]==="Inactive")&&(status[1]==="1" ? <Text type="secondary">Contact allumé</Text>:<Text type="secondary">Contact éteint</Text>)}</div> */}
        </div>}
        </>
        );
      },
    },
    {
      title: t("InventoryTable.Position"),
      dataIndex: "address",
      key: "address",
      width: 'max-content',
      render: (text) => (
        text[0] !== "" && <div className="InvDetBtn">
            <Link
              to={{ pathname: "/apercu" }}
              state={{ zone: text[2], path: "/inventory" }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              {text[2]&&<Button>Zone</Button>}
            </Link>
          <div>
            <Text>
              {text[2]&&<EnvironmentOutlined />} {text[0]}
            </Text>
          </div>
          <Text type="secondary">{text[1]}</Text>
        </div>
      ),
    },
    {
      title: t("Workspace"),
      dataIndex: "workspace",
      key: "workspace",
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        text[0]!==""&&<div>
         <div>
           <Text>
            {text[0]}
           </Text>
         </div>
         <Text type="secondary">{t("By")+" "+text[1]}</Text>
       </div>
     ),
    },
    {
      dataIndex: "actions",
      key: "actions",
    },
  ].filter((col) =>
    InvcheckedColumns.every(
      (y) => !col.dataIndex.toLowerCase().includes(y.toLowerCase())
    )
  );
  const [searchInput, setSearchInput] = useState("");
  const { company, role, workspaces } = props.props;
  const { loading, error, data,refetch } = useQuery(GET_VEHICLES, {
    variables: { _id: company, role: role, workspace: workspaces },fetchPolicy: "no-cache",
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.11"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("Maintenance.26"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.27"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteAsset({ variables: { _id,role } });
      },
    });
  };
  const [deleteAsset] = useMutation(DELETE_ASSET, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(company+'updateVehicle', (updatedVehicle) => {
      //   const updatedVehicles = data.getVehicles&&data.getVehicles.map((vehicle) =>
      //   vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      // );
      //   // update the data in the Apollo cache
      //   client.writeQuery({
      //     query: GET_VEHICLES,
      //     variables: { _id: company, role: role, workspace: workspaces },
      //     data: { getVehicles: updatedVehicles },
      //   });
      refetch();
      });
    }
    return () => {
      if (socket) {
        socket.off(company+'updateVehicle');
      }
    };
  }, [socket, data, client, company, role, workspaces]);

  if (loading) return <Loading />;
  if (error) return <></>;
  let InventoryData = [];
  if (data.getVehicles.length > 0) {
    const TableData = data.getVehicles.filter((vehicle) => {
      if (searchInput === "") {
        return vehicle;
      } else if (vehicle.profile.Model.toLowerCase().includes(searchInput.toLowerCase())) {
        return vehicle;
      }
    });
    InventoryData = TableData.map((vehicle) => {
      return {
        key: vehicle._id,
        model: vehicle.profile.Model,
        category: vehicle.profile.Categorie,
        marque: vehicle.profile.Marque,
        serialNumber: (
          <div className="InvDetBtn">
            <Text>{vehicle.profile.serialNumber}</Text>
              <Link
                to={{ pathname: "/profile" }}
              state={{ vehicle: vehicle, path: "/inventory" }}
               onContextMenu={(e) => {e.preventDefault(); }}
              >
            <Button>
                {t("Details")}
            </Button>
              </Link>
          </div>
        ),
        vehicle:isDesktop ? null:vehicle,
        SN:isDesktop ? null:vehicle.profile.serialNumber,
        horametre: (vehicle.hourMeter)&&convertToHoursMins(vehicle.hourMeter),
        odometer: (vehicle.odometer)&& parseFloat(vehicle.odometer).toFixed(2)>0?parseFloat(vehicle.odometer).toFixed(2)+" Km":0+" Km",
        status: [vehicle.status,vehicle.IgnitionStatus?vehicle.IgnitionStatus:null],
        address: [
          vehicle.address,
          dayjs(Math.floor(vehicle.updatedAt / 1000) * 1000).format(
            "DD MMM YYYY HH:mm"
          ),
          vehicle.zone
        ],
        updatedAt:vehicle.updatedAt,
        workspace:<div style={{ textAlign: "center" }}>{vehicle.workspace?.name ?? "-"}</div>,
        company:role==="Super Admin"?<div style={{ textAlign: "center" }}>{vehicle?.company?.name ?? "-"}</div>:"",
        createdAt:[dayjs(Math.floor(vehicle.profile.createdAt / 1000) * 1000).format(
            "DD MMM YYYY HH:mm"
          ),vehicle.createdBy],
        actions:  (role !== "AdminWorkspace"&&role !== "User")&&(
          <>
            <Dropdown 
              menu={{
                items: [
                  {
                    label: t("Maintenance.28"),
                    key: "1",
                    danger: true,
                  },
                ],
                onClick:({ key }) => {
                  switch(key) {
                    case "1":
                      showDeleteConfirm(vehicle._id);
                      break;
                    default:
                      break;
                  }
                }
              }}
              autoAdjustOverflow
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
              trigger={["click"]}
            >
              <Button type="text" style={{width:100}} ><EllipsisOutlined /></Button>
            </Dropdown>
          </>
        ),
      };
    });
  }
  const onChange = (checked, value) => {
    if (checked) {
      let x = InvcheckedColumns.filter((x) => x !== value);
      setInvcheckedColumns(x);
      localStorage.setItem("InvcheckedColumns", JSON.stringify(x));
    } else {
      setInvcheckedColumns([...InvcheckedColumns, value]);
      localStorage.setItem(
        "InvcheckedColumns",
        JSON.stringify([...InvcheckedColumns, value])
      );
    }
  };
  return (
    <Content className="InvContent">
      {
        isDesktop ? (
          <Table
        columns={role !== "Super Admin" ? (columns.filter(option=>option.title!==t("company"))):columns}
        size="small"
        dataSource={InventoryData}
        title={() => (
          <>
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="InventorySearchBar"
                placeholder={t("SearchInput")+" ("+InventoryData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              /></Col>
              <Col span={12}>              
              <div className="ButtonsDiv">
                {role!=="User"&&
                  <CreateAssetForm props={props.props} />}
                  <Tooltip
                    color={"white"}
                    trigger={"click"}
                    placement="bottomRight"
                    title={
                      <div>
                      {
                        Displayedcolumns.map((column)=>{
                          return(<div>
                          <Space size={"small"}>
                            <Switch
                              size="small"
                              value={column.dataIndex}
                              checked={
                                !InvcheckedColumns.some(
                                  (code) => code === column.dataIndex
                                )
                              }
                              onChange={(checked) =>
                                onChange(checked, column.dataIndex)
                              }
                            />
                            <Text>{column.title}</Text>
                          </Space>
                        </div>)
                        })
                      }
                      </div>
                    }
                  >
                    <Button style={{ marginRight:'5px' }}>
                    {isDesktop?<div><InsertRowLeftOutlined />{t("InventoryTable.Columns")}</div>:<div><InsertRowLeftOutlined /></div>}
                    </Button>
                  </Tooltip>
                  </div></Col>
                  </Row>
                </>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
        ):(
          <List
          pagination={{
            pageSize: 10,
            align:"start",
          }}
    header={
      <Row>
    <Col style={{ marginTop:10 }}>
    <Input
                style={{width:"70vw"}} 
                size="default"
                placeholder={t("SearchInput")+" ("+InventoryData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
    </Col>
    <Col style={{ marginLeft:5,marginTop:10 }}>
    {role!=="User"&&
                  <Button
                  type="primary"
                  style={{ marginRight:'5px' }}
                  onClick={() => {
                    navigate("/CreateAssetMobile")
                  }}
                >
                  <PlusOutlined />
                </Button>}
    </Col>            
    </Row>
    }
    itemLayout="horizontal"
    className="InvList"
    dataSource={InventoryData}
    renderItem={(item, index) => (
      <List.Item style={{margin:"5px 10px"}}>
       <div
              key={
                item.serialNumber
              }
              className="mydivouter"
            >
              <Card
                style={{ width: "100% ", height: "100%", }}
                onClick={async () => {
                  navigate("/details",{state: { vehicle: item.vehicle, path: "/inventory" }})
                }}
              >
                <div>{item.status[0]!==""&&<Status status={item.status} />}</div>
                <Space size="small">
                  <Text strong>{item.category}</Text>
                  <Text>{item.SN}</Text>
                </Space>
                <br/>
                <Space size="small">
                  <Text>{item.marque}</Text>
                  <Text>{item.model}</Text>
                </Space>
                <div>
                {item.address[0]!==""&&<Text type="secondary" style={{ width:"100%" }} ellipsis={{ rows:1 }}>
                  {item.address[0].length>40?item.address[0]:item.address[0]}
                </Text>}
                </div>
                {item.address!==""&&<div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  top: "0px",
                  right: "10px",
                 }}>
                  <Text type="secondary">
                    {dayjs(
                      Math.floor(item.updatedAt / 1000) * 1000
                    ).format("DD/MM/YYYY HH:mm")}
                  </Text>
                </div>}
              </Card>{" "}
            </div>
      </List.Item>
    )}
  />
        )
      }
      
    </Content>
  );
}
export default Inventory;
