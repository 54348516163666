import { React} from "react";
import {  Layout } from "antd";
import {  Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "../../css/ProfileTabs.css";
import LiveTab from "../../components/Profile/LiveTab.js";
import Breadcrumbs from "../../components/Multiple/Breadcrumbs.js";
import Loading from "../../components/Loading/Loading.js";
import { GET_VEHICLE } from "../../queries/mapQuery.js";
import { useQuery } from "@apollo/client";
const { Content } = Layout;


function VehicleHistorique(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const vehicle = location.state.vehicle;
  const path = location.state.path;
  const { loading, error, data } = useQuery(GET_VEHICLE, {
    variables: { _id: vehicle._id },
  });
  const items = [
    {
      key: "1",
      label: (
        <Link to={{ pathname: "/profile" }} state={{ vehicle,path }}>
          <div className="TabLink">{t("Tabs.Profile")}</div>
        </Link>
      ),
    },
    {
      key: "2",
      label: <div className="TabLink">Live</div>,
      children: <LiveTab data={data} props={props.props} vehicle={vehicle}/>,
    },
    {
      key: "3",
      label: (
        <Link to={{ pathname: "/history" }} state={{ vehicle,path }}>
          <div className="TabLink">{t("Tabs.History")}</div>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to={{ pathname: "/utilisation" }} state={{ vehicle,path }}>
          <div className="TabLink">{t("Tabs.Utilisation")}</div>
        </Link>
      ),
    },
  ];


  if (loading) return <Loading/>;
  if (error) return <></>;

  return (
    <Content className="LivePageContent">
      <Breadcrumbs data={data} vehicle={vehicle} path={path} props={props} />
      

      <Tabs defaultActiveKey="2" items={items} />
    </Content>
  );
}
export default VehicleHistorique;
