/* eslint-disable array-callback-return */
import { React, useState,useEffect, useContext } from "react";
import {
  Layout,
  Card,
  Col,
  Row,
  Typography,
  Modal,
  Button,
    List,
  Input,
  notification,
  Dropdown,
} from "antd";
import { ExclamationCircleFilled ,EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GET_CATEGORIES } from "../../queries/categorieQuery.js";
import Loading from "../Loading/Loading.js";
import Marque from "./Marque.js";
import { useQuery, useMutation } from "@apollo/client";
import { ADD_CATEGORIE ,DELETE_CATEGORIE,UPDATE_CATEGORIE} from "../../mutations/categorieMutations.js";
import { ValueContext } from "../../context/Context.js";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const errorNotification = (error) => {
  notification.error({
    message: "Error",
    description: error,
  });
};
function MarqueCatTab(props) {
  const { company, role } = props.props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState([]);
  const [namecat, setnamecat] = useState();
  const [data1, setData] = useState([]);
  const { isDesktop } = useContext(ValueContext);
   const { loading, error, data } = useQuery(GET_CATEGORIES, {
     variables: { company: company, role: role },
   });
    const [addcat] = useMutation(ADD_CATEGORIE, {
      onError: (error) => {
        errorNotification(error.message);
      },
      onCompleted:()=>{setOpen(false);},
      refetchQueries: [
        {
          query: GET_CATEGORIES,
          variables: { company: company, role: role },
        },
      ],
    });
    const [updatecat] = useMutation(UPDATE_CATEGORIE, {
      onError: (error) => {
        errorNotification(error.message);
      },
      onCompleted:()=>{setOpen(false);},
      refetchQueries: [
        {
          query: GET_CATEGORIES,
          variables: { company: company, role: role },
        },
      ],
    });
    const [deleteCategorie] = useMutation(DELETE_CATEGORIE, {
      refetchQueries: [
        {
          query: GET_CATEGORIES,
          variables: { company: company, role: role },
        },
      ],
    });
  useEffect(() => {
    if (!loading && !error && data?.getCategories?.length>0) { 
      setOpen2(data.getCategories?.map(() => false));
      setData(data.getCategories)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
    if (loading) return <Loading />;
    if (error) return <></>;
    console.log(data1);
  console.log(open2);
     const handleOpenChange = (flag) => {
       setOpen(flag);
     };
const handleChangeName = (index, newName) => {
  let newData = JSON.parse(JSON.stringify(data1));
  newData[index].name = newName;
  setData(newData);
};
  const toggleDropdown = (index,_id) => {
    const updatedVisibility = [...open2];
    updatedVisibility[index] = !updatedVisibility[index];
    setOpen2(updatedVisibility);
    (_id&&!updatedVisibility[index]&&data.getCategories[index].name!==data1[index].name)&&updatecat({
      variables: {
        name: data1[index].name,
        _id: _id,
        role: role,
      },
    });
  };
  const showDeleteConfirm = (_id, i) => {
    toggleDropdown(i);
    confirm({
      title: t("Maintenance.32"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.33"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteCategorie({ variables: { _id ,role:role} });
      },
    });
  };
  return (
    <>
      <Content
        style={{
          padding: '0 10px',
          height: "82vh",
          overflow: "auto",
        }}
      >
        <Row>
          <Col span={24}>
            <Card
              title={t("AssetCategorie")}
              extra={
                <Dropdown
                  key={"1"}
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <div style={{ width: 200 }}>
                            {" "}
                            <Input
                              onChange={(e) => {
                                setnamecat(e.target.value);
                              }}
                              value={namecat}
                            />
                            <div style={{ paddingTop: 10 }}>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  addcat({
                                    variables: {
                                      name: namecat,
                                      company: company,
                                      role: role,
                                    },
                                  });
                                }}
                              >
                                {t("CreateCategorie")}
                              </Button>
                            </div>
                          </div>
                        ),
                      },
                    ],
                  }}
                  onOpenChange={(open,info)=>{
                    if (info.source==='trigger') {
                      handleOpenChange(open)
                    }}}
                  placement="bottomRight"
                  trigger={["click"]}
                  open={open}
                >
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setnamecat("");
                    }}
                    type="primary"
                  >
                    {isDesktop ? t("AddCategorie"):<PlusOutlined />}
                  </Button>
                </Dropdown>
              }
            >
              {data?.getCategories?.length>0 && (
                <List
                  size="small"
                  dataSource={data1}
                  renderItem={(item,i) => (
                    <List.Item key={item._id} style={{ border: 'none' ,paddingBottom:10 }}>
                      <span style={{backgroundColor:"#E7EBF0",padding:'3px',borderRadius:"5px"}}><Text >{item.name}</Text></span>{" "}
                      <div style={{ float: "right", alignItems: "center" }}>
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: (
                                  <div style={{ maxWidth: 200 }}>
                                    {" "}
                                    <Input
                                      onChange={(e) => {
                                        handleChangeName(i, e.target.value);
                                      }}
                                      value={item.name}
                                    />
                                    <div style={{ paddingTop: 10 }}>
                                      <Button
                                        danger
                                        onClick={() => {
                                          showDeleteConfirm(item._id,i);
                                        }}
                                      >
                                        {t("DeleteCategorie")}
                                      </Button>
                                    </div>
                                  </div>
                                ),
                              },
                            ],
                          }}
                          onOpenChange={()=>{toggleDropdown(i,item._id)}}
                          placement="bottomRight"
                          trigger={["click"]}
                          open={open2[i]}
                        >
                          <EllipsisOutlined onClick={() => {
                              toggleDropdown(i,item._di)
                            }} style={{fontSize:24}} />
                        </Dropdown>
                      </div>{" "}
                    </List.Item>
                  )}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Marque props={props}/>
      </Content>
    </>
  );
}
export default MarqueCatTab;
