/* eslint-disable array-callback-return */
import { React, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Modal,
  Button,
  List,
  Input,
  notification,
  Dropdown,
} from "antd";
import { ExclamationCircleFilled, EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GET_MARQUES } from "../../queries/marqueQuery.js";
import Loading from "../Loading/Loading.js";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_MARQUE,
  DELETE_MARQUE,
  UPDATE_MARQUE,
} from "../../mutations/marqueMutations.js";
import { ValueContext } from "../../context/Context.js";
const { Text } = Typography;
const { confirm } = Modal;
const errorNotification = (error) => {
  notification.error({
    message: "Error",
    description: error,
  });
};
function Marque(props) {
  const { company, role } = props.props.props;
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState([]);
  const [namemarque, setnamemarque] = useState();
  const [data1, setData] = useState([]);
  const { loading, error, data } = useQuery(GET_MARQUES, {
    variables: { company: company, role: role },
  });
  const [addmarque] = useMutation(ADD_MARQUE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      setOpen(false);
    },
    refetchQueries: [
      {
        query: GET_MARQUES,
        variables: { company: company, role: role },
      },
    ],
  });
  const [updatemarque] = useMutation(UPDATE_MARQUE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      setOpen(false);
    },
    refetchQueries: [
      {
        query: GET_MARQUES,
        variables: { company: company, role: role },
      },
    ],
  });
  const [deleteMarque] = useMutation(DELETE_MARQUE, {
    refetchQueries: [
      {
        query: GET_MARQUES,
        variables: { company: company, role: role },
      },
    ],
  });
  useEffect(() => {
    if (!loading && !error && data?.getMarques?.length > 0) {
      setOpen2(data.getMarques?.map(() => false));
      setData(data.getMarques);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (loading) return <Loading />;
  if (error) return <></>;
  console.log(data1);
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };
  const handleChangeName = (index, newName) => {
    let newData = JSON.parse(JSON.stringify(data1));
    newData[index].name = newName;
    setData(newData);
  };
  const toggleDropdown = (index, _id) => {
    const updatedVisibility = [...open2];
    updatedVisibility[index] = !updatedVisibility[index];
    setOpen2(updatedVisibility);
    _id &&
      !updatedVisibility[index] &&
      data.getMarques[index].name !== data1[index].name &&
      updatemarque({
        variables: {
          name: data1[index].name,
          _id: _id,
          role: role,
        },
      });
  };
  const showDeleteConfirm = (_id, i) => {
    toggleDropdown(i);
    confirm({
      title: t("Maintenance.35"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.36"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteMarque({ variables: { _id, role: role } });
      },
    });
  };
  return (
    <>
      
        <Row style={{ paddingTop: 40 }}>
          <Col span={24}>
            <Card
              title={t("AssetMarque")}
              extra={
                <Dropdown
                  key={"1"}
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <div style={{ width: 200 }}>
                            {" "}
                            <Input
                              onChange={(e) => {
                                setnamemarque(e.target.value);
                              }}
                              value={namemarque}
                            />
                            <div style={{ paddingTop: 10 }}>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  addmarque({
                                    variables: {
                                      name: namemarque,
                                      company: company,
                                      role: role,
                                    },
                                  });
                                }}
                              >
                                {t("CreateMarque")}
                              </Button>
                            </div>
                          </div>
                        ),
                      },
                    ],
                  }}
                  onOpenChange={(open,info)=>{
                    if (info.source==='trigger') {
                      handleOpenChange(open)
                    }}}
                  placement="bottomRight"
                  trigger={["click"]}
                  open={open}
                >
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setnamemarque("");
                    }}
                    type="primary"
                  >
                    {isDesktop ? t("AddMarque"):<PlusOutlined />}
                  </Button>
                </Dropdown>
              }
            >
              {data?.getMarques?.length > 0 && (
                <List
                  size="small"
                  // bordered
                  dataSource={data1}
                  renderItem={(item, i) => (
                    <List.Item
                      key={item._id}
                      style={{ border: "none", paddingBottom: 10 }}
                    >
                      <span
                        style={{
                          backgroundColor: "#E7EBF0",
                          padding: "3px",
                          borderRadius: "5px",
                        }}
                      >
                        <Text>{item.name}</Text>
                      </span>{" "}
                      <div style={{ float: "right", alignItems: "center" }}>
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: (
                                  <div style={{ maxWidth: 200 }}>
                                    {" "}
                                    <Input
                                      onChange={(e) => {
                                        handleChangeName(i, e.target.value);
                                      }}
                                      value={item.name}
                                    />
                                    <div style={{ paddingTop: 10 }}>
                                      <Button
                                        danger
                                        onClick={() => {
                                          showDeleteConfirm(item._id, i);
                                        }}
                                      >
                                        {t("DeleteMarque")}
                                      </Button>
                                    </div>
                                  </div>
                                ),
                              },
                            ],
                          }}
                          onOpenChange={() => {
                            toggleDropdown(i, item._id);
                          }}
                          placement="bottomRight"
                          trigger={["click"]}
                          open={open2[i]}
                        >
                          <EllipsisOutlined
                            onClick={() => {
                              toggleDropdown(i, item._di);
                            }}
                            style={{ fontSize: 24 }}
                          />
                        </Dropdown>
                      </div>{" "}
                    </List.Item>
                  )}
                />
              )}
            </Card>
          </Col>
        </Row>
    </>
  );
}
export default Marque;
