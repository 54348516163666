/* eslint-disable array-callback-return */
import { React,useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Layout ,AutoComplete, Card, Breadcrumb} from "antd";
import { Typography, Space, Input, Form, Select, Button ,notification} from "antd";
import { ADD_ZONE } from "../mutations/zoneMutation.js";
import { GET_ZONES } from "../queries/zoneQuery.js";
import {useMutation} from "@apollo/client";
import { Link,useNavigate } from "react-router-dom";
import "../css/Zone.css";
import ZoneMap from "../components/map/ZoneMap.js";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { LeftOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Text, Title } = Typography;

function CreateZone(props) {
  // const map = useMap();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company,companyname, role,_id,workspaces,fullworkspaces } = props.props;
  const [name, setname] = useState();
  const [address, setaddress] = useState();
  const [categorie, setcategorie] = useState([]);
  const [Works, setWorks] = useState([]);
  const [bounds, setbounds] = useState([]);
  const [typeshape, settypeshape] = useState();
  const [coordinates, setcoordinates] = useState();
  const [radius, setradius] = useState();
  const [e, sete] = useState();

  const successNotification = () => {
    notification.open({
      description: t("Notification.17"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [addZone] = useMutation(ADD_ZONE,{
    refetchQueries: [{ query: GET_ZONES, variables: { _id: company, role: role, workspace: workspaces } }],
    onCompleted: () => {
      successNotification();
    },
  });
  const workspaceoptions = [];
   fullworkspaces &&
     fullworkspaces.map((ele) => {
       if (workspaces && workspaces.includes(ele._id) ) {
         workspaceoptions.push({
           value: ele._id,
           label: ele.name,
         });
        } else if(workspaces?.length<1) {
         workspaceoptions.push({
           value: ele._id,
           label: ele.name,
         });
        
       }
     });
function GeoSearchInput() {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    // Perform geosearch request for suggestions
    setSuggestions([]);
    provider
      .search({ query: query })

      .then((result) => {
        setSuggestions(result);
      })
      .catch((error) => {
        console.error("Search error:", error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSearch = (value) => {
    if (value === "") {
      setSuggestions([])
    }
    setQuery(value);
    // setaddress(value)
  };

  const handleSelect = (value) => {
    setaddress(value)
     for (let i = 0; i < suggestions.length; i++) {
       let ele = suggestions[i];
       if (ele.label === value) {
         setbounds(ele.bounds);
         break;
       }
     }
    // Do something with the selected value (e.g., set it in state)
  };

  return (
    <AutoComplete
      style={{
        width: "100%",
      }}
      defaultValue={address}
      dataSource={suggestions.map((suggestion) => suggestion.label)}
      placeholder="Enter location"
      // filterOption={false}
      // onChange={(e)=>{console.log(e)}}
      onSearch={handleSearch}
      onSelect={handleSelect}
    />
  );
}

  const submit = async () => {
    if (!typeshape) {
      sete("error")
      return
    }
    const { error, data }= await addZone({
      variables:
        {categorie:categorie, name, role,company,coordinates,radius,address,createdBy:_id,
          workspace: Works ? (Works.includes("All") ? null:Works): null, }
  })
    if (data) {
      navigate("/zone");
    } else {
      return <p>{ error }</p>
    }
  }

  const categorieoptions = [
    { value: "career", label: t("zone.career") },
    { value: "worksite", label: t("zone.worksite") },
  ];

  function creteshape(type,coordinates,radius) {
    settypeshape(type);
    setcoordinates(coordinates);
    setradius(radius);
    sete()
  }
 
  const selectProps = {
    categorie,
    options: categorieoptions,
    onChange: (newValue) => {
      setcategorie(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("placeholder.1"),
    maxTagCount: "responsive",
  };
  return (
    <Content
      style={{
        height: "100vh",
        width: "100wh",
        padding: "20px 40px 50px 30px",
        overflowY: "scroll",
      }}
    >
    <div className="CRZoneCard" >
    <Breadcrumb
        items={[
          {
            title: (
              <Link to={"/zone"}>
                <div className="bredIcon">
                  <Space>
                    <LeftOutlined />
                    Zone
                  </Space>
                </div>
              </Link>
            ),
          },
        ]}
        style={{ marginBottom:20 }}
      />
      <Card
    title={<>
      <Title level={4}>{t("zone.1")}</Title>
      <Text>{t("zone.2")}</Text>
    </>}
    bordered={false}
  >
      <Form
        initialValues={{
          remember: true,
        }}
        labelCol={{
          flex: "100px",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={submit}
      >
        <Space
          direction="vertical"
          style={{
            width: "90%",
          }}
        >
          <Form.Item
            label={t("Alerts.16")}
            name="name"
            rules={[
              {
                required: true,
                message: t("FormValidations.34"),
              },
            ]}
          >
            <Input
              style={{
                width: 200,
              }}
              placeholder={t("Alerts.16")}
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("InventoryTable.Category")}
            name="categorie"
            rules={[
              {
                required: true,
                message: t("FormValidations.4"),
              },
            ]}
          >
            <Select style={{ width:'100%', }} {...selectProps} />
          </Form.Item>
          <Form.Item
            label={t("HistoryTable.3")}
            name="address"
          >
            <GeoSearchInput />  
          </Form.Item>
          {role !== "Super Admin" && (
              <Form.Item
                name="workspace"
                label={t("Workspace")}
                rules={[
              {
                required: true,
                message: t("FormValidations.49"),
              },
            ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  onChange={(newValue) => {
                  setWorks(newValue);}
                  } 
                  placeholder={t("Alerts.14")}
                  options={role !== "Admin" ? workspaceoptions : [{value: "All", label: companyname},...workspaceoptions]}
                />
              </Form.Item>
            )}
          <Form.Item label="     ">
            <ZoneMap creteshape={creteshape} bounds={bounds} setbounds={setbounds} />
            <div style={{ color: "red" }}>{e && t("FormValidations.37")}</div>
          </Form.Item>
          <div className="ButtonsDiv" style={{ marginTop: 20 }}>
            <Space direction="horizontal">
              <Link to={{ pathname: "/zone" }}>
                <Button>{t("Alerts.9")}</Button>
              </Link>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("Alerts.10")}
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Space>
      </Form>
      </Card>
    </div>
    </Content>
  );
}
export default CreateZone;
