import { React,useEffect,useState } from "react";
import { Layout, Table,Typography,Row,Col, Statistic, Select } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GET_UTILISATIONBYPERIOD } from "../../queries/utilisationQuery.js";
import { useQuery } from '@apollo/client';
import { io } from "socket.io-client";
import { convertToHoursMins,convertSecondsToHoursMins } from '../../constants/ConvertHora.js';
import "../../css/Mobile.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Loading from "../../components/Loading/Loading.js";
import { Button, Footer, TabBar } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCar, faChartLine, faChevronLeft, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const { Content } = Layout;
const { Text } = Typography;

function MobileUtilisation(props) {
    const { t } = useTranslation();
    let location = useLocation();
    const navigate = useNavigate();
  const vehicle = location.state.vehicle;
  const datav = location.state.data;
  const [period, setperiod] = useState("Last 7 Day");
  let usagehr = datav.getVehicle.profile.usage === "hr";
  const { loading, error, data, refetch } = useQuery(GET_UTILISATIONBYPERIOD, { variables: { vehicle: vehicle._id,period:period }, });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(vehicle._id + "utlisation", () => {
        refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data]);
  if (loading) return <Loading/>;
  if (error) return <></>;
  let avg = 0;
  let totalut = 0;
  let totalralentie = 0;
  let totalprod = 0;
  const periodoptions = [
    {
      value: "Last 7 Day",
      label: t("Alerts.18"),
    },
    {
      value: "Last 15 Day",
      label: t("Alerts.19"),
    },
    {
      value: "Last Month",
      label: t("Alerts.20"),
    },
  ];
  const InventoryData = 
    data && data.getUtilisationByPeriod?.slice(0).reverse().map((ele) => {
      totalut = totalut + (usagehr ? parseInt(ele.utilisation) : parseFloat(ele.utilisationKM));
      totalralentie = totalralentie + (usagehr ? parseInt(ele.ralentie) :0);
      totalprod = totalprod + (usagehr ? parseInt(ele.productiv) :0);
      return {
        utilisation: parseFloat(ele.utilisationKM).toFixed(2)>0?parseFloat(ele.utilisationKM).toFixed(2) + " Km":0+" Km",
        date: dayjs(Math.floor(ele.date / 1000) * 1000).format("DD MMM YYYY"),
        idle: ele.ralentie,
        prod:ele.productiv,
        idelpercent:(ele.ralentie===0 || ele.utilisation===0) ? 0:((ele.ralentie/(ele.utilisation*60))<1 ? ((ele.ralentie/(ele.utilisation*60))*100).toFixed(1):100),
      };
    });
    let chartData = {
      labels: InventoryData?.map((item) => item.date).reverse(),
      datasets: [
        {
          label: t("UsageKM"),
         data: InventoryData?.map((item,i) => {
           return data.getUtilisationByPeriod[i].utilisationKM
         }),
         backgroundColor: "#95BDFF",
         borderColor: "#95BDFF",
         barPercentage: 0.2,
       },
     ],
  };
    let chartData4 = {
      labels: InventoryData?.map((item) => item.date).reverse(),
      datasets: [
        {
          label: t("UsageHours"),
         data: InventoryData?.map((item,i) => {
           return parseFloat(data.getUtilisationByPeriod[i].utilisation)/60
         }),
         backgroundColor: "#95BDFF",
         borderColor: "#95BDFF",
         barPercentage: 0.2,
       },
     ],
  };
    let chartData2 = {
      labels: InventoryData?.map((item) => item.date).reverse(),
      datasets: [
        {
          label: t('TauR'),
         data: InventoryData?.map((item,i) => {
           return data.getUtilisationByPeriod[i].ralentie
         }),
         backgroundColor: "#95BDFF",
         borderColor: "#95BDFF",
         barPercentage: 0.2,
       },
     ],
  };
    let chartData3 = {
      labels: InventoryData?.map((item) => item.date).reverse(),
      datasets: [
        {
          label: t('Idle.5'),
         data: InventoryData?.map((item,i) => {          
           return item.idelpercent
         }).reverse(),
         backgroundColor: "#95BDFF",
         borderColor: "#95BDFF",
         barPercentage: 0.2,
       },
     ],
  };
  const options = {
    scales: {
      y: {
        min:0,
        title: {
          display: true,
          text: "Km", // Add your Y-axis label here
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = t("UsageKM")+": "+(context.parsed.y).toFixed(2)+" Km";
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
  const options2 = {
    scales: {
      y: {
        min:0,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
              return (value/3600).toFixed(0);
          }
      },
      title: {
        display: true,
        text: t("hours"), // Add your Y-axis label here
        font: {
          size: 16,
        },
      },
        
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = convertSecondsToHoursMins(context.parsed.y);
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
  const options3 = {
    scales: {
      y: {
        min:0,
      title: {
        display: true,
        text: "%", // Add your Y-axis label here
        font: {
          size: 16,
        },
      },
        
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = context.parsed.y+" %";
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
  const options4 = {
    scales: {
      y: {
        min:0,
      title: {
        display: true,
        text: t("hours"), // Add your Y-axis label here
        font: {
          size: 16,
        },
      },
        
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = convertToHoursMins(context.parsed.y*60);
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
   return (
    <>
    <Content className="MUContent">
      <Row style={{ marginBottom:10 }}>
        <Col span={12}>
        <Button className='RHistoryBtn2' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Col>
        <Col span={12}>
      <div className="ButtonsDiv"><Select  value={period}
                      options={periodoptions}
                      onChange={(e) => {
                        setperiod(e);
                      }}/></div>
        </Col>
      </Row>
      <Row gutter={[16,16]} className="Brow"  >
        <Col className="SrowCol"  sm={24} md={18}>
        <Row className="Srow1" >
          <Bar data={chartData} className="barCharts" options={options} />
        </Row></Col>
{usagehr&&<Col className="SrowCol"  sm={24} md={18}>
        <Row className="Srow1" >
          <Bar data={chartData4} className="barCharts" options={options4} />
        </Row></Col>}
        <Col className="SrowCol"  sm={24} md={18}>
        <Row className="Srow1" >
          <Bar data={chartData2} className="barCharts" options={options2} />
        </Row></Col>
        <Col className="SrowCol"  sm={24} md={18}>
        <Row className="Srow1" >
          <Bar data={chartData3} className="barCharts" options={options3} />
        </Row></Col>
      </Row>
      </Content>
    </>
  );
}
export default MobileUtilisation;
