/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Modal,
  Row,
  Col,
  Dropdown, Avatar,
  notification,
  Tag,
  List,
  Card,
  Space,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USERS } from "../../queries/UserQuery.js";
import { DELETE_USER } from "../../mutations/userMutations.js";
import {
  SearchOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import Loading from "../../components/Loading/Loading.js";
import CreateMemberForm from "../Forms/CreateMemberForm.js";
import UpdateMemberForm from "../Forms/UpdateMemberForm.js";
import { ValueContext } from "../../context/Context.js";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
export default function UsersTab(props) {
  const { company, role, workspaces,_id } = props.props;
  const { isDesktop } = useContext(ValueContext);
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Administration.name"),
      dataIndex: "name",
      key: "name",
      render:(name)=>(
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
  <Avatar
    style={{
      backgroundColor: 'white',
      borderColor:'#D8D8D8',
      color: 'black',
    }}
  >
    {name[0][0] ? name[0][0].toUpperCase() + name[1][0].toUpperCase() : name[2][0].toUpperCase()}
  </Avatar> 
  <div style={{ marginLeft: '8px', display: 'flex', flexDirection: 'column' }}>
  {name[0][0] ? <><Text style={{ marginBottom: '-5px' }}>{name[0] + " " + name[1]}</Text>
  <Text type="secondary">{name[2]}</Text></>: <Text>{name[2]}</Text>}
  </div>
</div>
      ),
    },
    {
      title: t("InventoryTable.Status"),
      dataIndex: "status",
      key: "status",
      render:(status)=>(
        status ? <Tag color="#BEF0CB">Active</Tag> :<Tag color="#F0EEED">{t('Pending')}</Tag>
      ),
    },
    {
      title: t("Administration.role"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("AdministrationTab.workspace"),
      dataIndex: "workspace",
      key: "workspace",
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];
  const [searchInput, setSearchInput] = useState("");

  const { loading, error, data } = useQuery(GET_USERS, {
    variables: { _id: company, role: role, workspace: workspaces },
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.13"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [deleteuser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  if (loading) return <Loading />;
  if (error) return <></>;
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("FormValidations.20"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.21"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteuser({ variables: { _id, role } });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const TableData = data.getUsers.filter((user) => {
    if (searchInput === "") {
      return user;
    } else if ((user.fname&&(user.fname+user.lname).toLowerCase().includes(searchInput.toLowerCase()))|| user.email.toLowerCase().includes(searchInput.toLowerCase())) {
      return user;
    }
  });
  const MembersData = TableData.map((user,index) => {
    return {
      key: user._id,
      name: [user.fname ?? "", user.lname ?? "", user.email],
      status: user.fname ? true : false,
      role: t(user.role.name.toString()),
      company: user?.company?.name,
      workspace:user.workspace.map((workspace,index) => workspace.name + ((index<(user.workspace.length-1)) ? ", ":"")),
      action: (user.role.name!=="User" )&&(
        <>
          <Space direction="vertical">
          <Dropdown
            menu={{
              items: [
                {
                  label: <UpdateMemberForm props={props.props} user={user} />,
                  key: "0",
                },
                (user._id !== _id)&&{
                  label: (
                    <Button
                      type="text"
                      block
                      onClick={() => {
                        showDeleteConfirm(user._id);
                      }}
                    >
                      <Text type="danger">{t("DeleteMember")}</Text>
                    </Button>
                  ),
                  key: "1",
                },
              ],
            }}
              arrow={{
                pointAtCenter: true,
              }}
            trigger={["click"]}
          >
            <Button type="text" icon={<EllipsisOutlined />}></Button>
          </Dropdown>
          </Space>
        </>
      ),
    };
  });
  return (
    <Content className="MembersContent" >
      {isDesktop ? <Table
        columns={role !== "Super Admin" ? (columns.filter(option=>option.title!==t("company"))):columns}
        size="small"
        dataSource={MembersData}
        title={() => (
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("AlertsSearchInput")+" ("+MembersData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                <CreateMemberForm props={props.props} />
                </div>
            </Col>
        </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />:<List

      header={
        <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("AlertsSearchInput")+" ("+MembersData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                <CreateMemberForm props={props.props} />
                </div>
            </Col>
        </Row>
      }
      pagination={{
        pageSize: 10,
        align:"start",
      }}
      itemLayout="horizontal"
      className="AlertList3"
      dataSource={MembersData}
      renderItem={(item) => (
        <List.Item style={{margin:"5px 10px"}}>
         <div
                    key={
                      item.key
                    }
                    className="mydivouter2"
                  >
                      <div style={{ 
                        position: "absolute",
                        zIndex: 2,
                        top: "20px",
                        right: "20px",
                       }}>
                        <Row>
                       {item.action}
                        </Row>
                      </div>
                    <Card style={{width: "100% ", height: "100%"}}   >
                  <div style={{margin:"20px 10px 10px 10px"}}>
                            <Row>
                              <Col>
                              <Avatar
                                style={{
                                  backgroundColor: 'white',
                                  borderColor:'#D8D8D8',
                                  color: 'black',
                                }}
                                size={{
                                  xs:50,
                                  lg: 80,
                                  xl: 80,
                                  xxl: 100,
                                }}
                              >
                                {item.name[0][0] ? item.name[0][0].toUpperCase() + item.name[1][0].toUpperCase() : item.name[2][0].toUpperCase()}
                              </Avatar>
                              </Col>
                              <Col>
                              <Row>
                              <div style={{ marginLeft: '8px', display: 'flex', flexDirection: 'column' }}>
                              {item.name[0][0] ? <><Text style={{ marginBottom: '-5px' }}>{item.name[0] + " " + item.name[1]}</Text>
                              <Text type="secondary">{item.name[2]}</Text></>: <Text>{item.name[2]}</Text>}
                              </div>
                              </Row>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col></Col>
                              <Col><Text strong>{item.name}</Text></Col>
                            </Row>
                            <Row>
                              <Col><Text type="secondary">Type: </Text></Col>
                              <Col><Text type="secondary" style={{marginLeft:5}}>{t(item.type+"2")}</Text></Col>
                            </Row>
                            <Row>
                              <Col>
                              <div>
                                <Text type="secondary">{t("By2")+" "+item.createdBy}</Text>
                              </div></Col>
                            </Row> */}
                  </div>
                    <Row gutter={[16,16]} style={{ marginLeft:5,marginTop:7 }}>
                    </Row>
                    </Card>{" "}
                  </div>
        </List.Item>
      )}
    />}
    </Content>
  );
}
