import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { useMutation } from '@apollo/client';
import { SEND_RESETTOKEN } from '../../mutations/userMutations.js';
import { Button, Form, Input,Divider,Typography } from "antd";
import { Layout,Card } from "antd";
import '../../css/auth.css'
import { UserOutlined } from '@ant-design/icons';
const { Content,Header } = Layout;
const { Title,Text } = Typography;

function SendResetPassword() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);
    const [sendResetPassword,{error,data}] = useMutation(SEND_RESETTOKEN);

    const handleClick = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds delay
    };

    const submit = async () => {
      form
      .validateFields()
      .then(async (values) => {
          await sendResetPassword({
            variables: {
              email: values.email,
              lang: localStorage.getItem('language')
            },
          });

      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
        }
    return (
        <>
          <Header className='loginHeader'>
        <div className="nav-wrapper w-container">
        <div className="nav-logo">
            <a href="/" className="link-block-header-logo w-inline-block w--current"><Title level={4}>DeviceSpeak</Title></a>
          </div>
          <nav class="nav-links nav-menu">
          <a href="/" className="nav-link-block w-inline-block">
            <div className="text-block-3">Home</div>
          </a>
          <a href="/login" className="nav-link-block w-inline-block">
            <div className="text-block-3">{t("Login")}</div>
          </a>
          </nav>
        </div>
        </Header>
    <Content className='PassContent'
          style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: "600px",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
        <Card className="Authcard"  bordered={true}   style={{
          textAlign: "center",
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  }} >
        <Title level={4}>{!data ? t("ForgotPass"):t("Instructions")}</Title>
        <Divider />
        {!data ?(<><Form
        layout="vertical"
        form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={submit}
    >
            <Form.Item
            name="email"
            label={t("FormeAddUser.lableforemail")}
            rules={[
              {
                type: "email",
                message: t("FormValidations.16"),
              },
              {
                required: true,
                message: t("FormValidations.15"),
              },
            ]}
          >
              <Input
                prefix={<UserOutlined />}
                className='authInput'
                style={{width: "100%",}}
                placeholder="Email"
                type="email"
              />
            </Form.Item>

            <Form.Item>
              <Button block ghost
                style={{
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "5px",

                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={handleClick}
              >
                Email instructions
              </Button>
            </Form.Item>
            {error&&<Text type='danger'>{error.message}</Text>}
            </Form>
            <div>
          <Link to={"/"}><Text type="secondary" underline style={{ fontSize:"12px" }}>{t("Back")}</Text></Link>
          </div></>):<Text  style={{ fontSize:"12px" }}>{t("CheckEmail")}</Text>}
        </Card>
      </Content>
        </>
    )
}

export default SendResetPassword;