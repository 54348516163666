import { React,useEffect,useState,useContext } from "react";
import { Layout, Table,Typography,Row,Col, Statistic, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { GET_UTILISATIONBYPERIOD } from "../../queries/utilisationQuery.js";
import { ValueContext } from '../../context/Context.js';
import { useQuery } from '@apollo/client';
import { io } from "socket.io-client";
import { convertToHoursMins,convertSecondsToHoursMins } from '../../constants/ConvertHora.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading/Loading.js";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const style2 = { borderRadius:20,margin:10};

function UtilisationTab(props) {
  const { isDesktop } = useContext(ValueContext);
    const { t } = useTranslation();
    let location = useLocation();
  const vehicle = location.state.vehicle;
  const [period, setperiod] = useState("Last 7 Day");
  let usagehr = props.vehicle.profile.usage === "hr";
  const { loading, error, data, refetch } = useQuery(GET_UTILISATIONBYPERIOD, { variables: { vehicle: vehicle._id,period:period }, });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(vehicle._id + "utlisation", () => {
        refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data]);
  if (loading) return <Loading/>;
  if (error) return <></>;
    const columns = [
        {
          title:  usagehr?t("UsageHours"):t("UsageKM"),
          dataIndex: "utilisation",
          key: "utilisation",
        },
        {
          title:  t("IdleHours"),
          dataIndex: "idle",
          key: "idle",
        },
        {
          title:  t("ProductivityHours"),
          dataIndex: "prod",
          key: "prod",
        },
        {
          title: t("Idle.5"),
          dataIndex: "idelpercent",
        },
        {
          title:  t("zone.8"),
          dataIndex: "conso",
          key: "conso",
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
  ];
  let avg = 0;
  let totalut = 0;
  let totalralentie = 0;
  let totalprod = 0;
  const periodoptions = [
    {
      value: "Last 7 Day",
      label: t("Alerts.18"),
    },
    {
      value: "Last 15 Day",
      label: t("Alerts.19"),
    },
    {
      value: "Last Month",
      label: t("Alerts.20"),
    },
  ];
  const InventoryData = 
    data && data.getUtilisationByPeriod?.slice(0).reverse().map((ele) => {
      totalut = totalut + (usagehr ? parseInt(ele.utilisation) : parseFloat(ele.utilisationKM));
      totalralentie = totalralentie + (usagehr ? parseInt(ele.ralentie) :0);
      totalprod = totalprod + (usagehr ? parseInt(ele.productiv) :0);
      return {
        utilisation: usagehr
          ? convertToHoursMins(ele.utilisation)
          : parseFloat(ele.utilisationKM).toFixed(2)>0?parseFloat(ele.utilisationKM).toFixed(2) + " Km":0+" Km",
        conso: usagehr
          ? ((ele.utilisation * props.vehicle.profile.Fuel) / 60).toFixed(2)>0?((ele.utilisation * props.vehicle.profile.Fuel) / 60).toFixed(2)+" L":0+" L"
          : ((ele.utilisationKM) * props.vehicle.profile.Fuel).toFixed(2)>0?((ele.utilisationKM) * props.vehicle.profile.Fuel/100).toFixed(2)+" L":0+" L",
        date: dayjs(Math.floor(ele.date / 1000) * 1000).format("DD MMM YYYY"),
        idle: convertSecondsToHoursMins(ele.ralentie),
        prod:convertSecondsToHoursMins(ele.productiv),
        idelpercent:(ele.ralentie===0 || ele.utilisation===0) ? 0+" %":((ele.ralentie/(ele.utilisation*60))<1 ? ((ele.ralentie/(ele.utilisation*60))*100).toFixed(1)+" %":100 +" %"),
      };
    });
    let chartData = {
      labels: InventoryData?.map((item) => item.date).reverse(),
      datasets: [
        {
          label: usagehr?t("UsageHours"):t("UsageKM"),
         data: InventoryData?.map((item,i) => {
           avg=avg+parseFloat(item.conso)
           return usagehr ? ((data.getUtilisationByPeriod[i].utilisation)/60).toFixed(2) : data.getUtilisationByPeriod[i].utilisationKM
         }),
         backgroundColor: "#95BDFF",
         borderColor: "#95BDFF",
         barPercentage: 0.2,
       },
     ],
  };
  const options = {
    scales: {
      y: {
        min:0,
        title: {
          display: true,
          text: usagehr? t("hours"):"Km", // Add your Y-axis label here
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label 
                  if (context.parsed.y !== null) {
                    label = usagehr ? t("UsageHours")+": "+convertToHoursMins(context.parsed.y*60) :t("UsageKM")+": "+(context.parsed.y).toFixed(2);
                  }
                  return label;
              }
        }
      },
      legend: {
        onClick: function () {
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };
   
   return (
    <>
    <Content>
      <Row style={{ marginBottom:10 }}>
      <div className="ButtonsDiv">
      {/* <RangePicker presets={[
        {
          label: 'Yesterday',
          value: dayjs().add(-1, 'd'),
        },
        {
          label: 'Last Week',
          value: dayjs().add(-7, 'd'),
        },
        {
          label: 'Last Month',
          value: dayjs().add(-1, 'month'),
        },
      ]} /> */}
        <Select  value={period}
                      options={periodoptions}
                      onChange={(e) => {
                        setperiod(e);
                      }}/></div>
      </Row>
      <Row gutter={[16,16]} className="Brow"  >
        <Col className="SrowCol"  sm={24} md={18}>
        <Row className="Srow1" >
          <Line data={chartData} className="barCharts" options={options} />
        </Row></Col>
        <Col  sm={24} md={6}>
        <Row className="Srow2" >
        <Col span={24}><Statistic title={<Text strong>{usagehr?t("zone.24"):t("zone.25")}</Text>} valueStyle={{ fontWeight:500,fontSize:26 }} value={usagehr?convertToHoursMins(totalut):(totalut).toFixed(2)+" KM"}  /></Col>
        {usagehr && <Col span={24}><Statistic title={<Text strong>{ t("IdleHourstotal") }</Text>} valueStyle={{ fontWeight: 500, fontSize: 26 }} value={convertSecondsToHoursMins(totalralentie)} /></Col>}
        {usagehr && <Col span={24}><Statistic title={<Text strong>{ t("ProductivityHourstotal") }</Text>} valueStyle={{ fontWeight: 500, fontSize: 26 }} value={convertSecondsToHoursMins(totalprod)} /></Col>}
        </Row>
        <Row className="Srow3" >
        <Col span={24}><Statistic title={<Text strong>{t("zone.26")}</Text>} valueStyle={{ fontWeight:500,fontSize:26 }} value={(avg).toFixed(2)+" L"}  /></Col>
        <Col span={24}><Statistic title={<Text strong>{t("zone.8")}</Text>} valueStyle={{ fontWeight:500,fontSize:26 }} value={(props.vehicle.profile.Fuel)+(usagehr?" L/H":" L/100 Km")}  /></Col>
        </Row>
        </Col>
      </Row>
        <Table
          bordered
          style={{ padding: "20px 0px 20px 0px",borderRadius: 10 }}
          columns={usagehr?columns:[...columns.slice(0, 1), ...columns.slice(3)]}
        size="small"
          dataSource={InventoryData}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            position: ["bottomLeft"],
          }}
        />
      </Content>
    </>
  );
}
export default UtilisationTab;
