import { React,useContext } from "react";
import { Layout } from "antd";
import { Tabs, Badge, Typography} from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../css/Alerts.css";
import Alerts from "../../components/alerts/AlertsComponent.js";
import { ValueContext } from "../../context/Context.js";
const { Content } = Layout;
const { Title } = Typography;

function Alert(props) {
  const { t } = useTranslation();
  const { value,isDesktop} = useContext(ValueContext);
  
  const items = [
    {
      key: "1",
      label: <div className="TabLink">{t("AlertTab.alert")}</div>,
      children: <Alerts props={props.props} />,
    },
    {
      key: "2",
      label: (
        <Link to={{ pathname: "/alerts/notification" }}>
            <div className="TabLink">{t("AlertTab.notification")} <Badge size="small" count={value}/></div>
        </Link>
      ),
    },
  ];
  return (
    <Content className="AlertContent">
      {isDesktop ? <><Title level={3}>{t("AlertTab.alert")}</Title><Tabs defaultActiveKey="1" items={items}  /></> :<Alerts props={props.props} />}
    </Content>
  );
}
export default Alert;
