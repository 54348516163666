/* eslint-disable array-callback-return */
import { React, useState,useContext } from "react";
import { ValueContext } from "../../context/Context.js";
import { useTranslation } from "react-i18next";
import { CREATE_ASSET } from "../../mutations/assetMutations.js";
import { GET_CATEGORIES } from "../../queries/categorieQuery.js";
import { GET_MARQUES } from "../../queries/marqueQuery.js";
import { Input, Button, Form, Modal, Space, Select, notification } from "antd";
import { useMutation, useLazyQuery,useQuery } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";
import { GET_VEHICLES } from "../../queries/mapQuery.js";
import { GET_COMPANYS } from "../../queries/companyQuery.js";
import Loading from "../../components/Loading/Loading.js";
function CreateAssetForm(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    // notification.open({
    //   description: t("Notification.1"),
    //   duration:2,
    //   closeIcon: false,
    //   className:'CustomSuccess'
    // });
    window.location.reload();
  };
  const [companystatus, setcompanystatus] = useState("");
  const { company,companyname, role, workspaces, _id, fullworkspaces } = props.props;
  const [comp, setcomp] = useState("");
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
   const [usage, setusage] = useState("hr");
   const { loading:loadingC, error:errorC, data:dataC } = useQuery(GET_CATEGORIES, {
     variables: { company: company, role: role },
   });
   const { loading:loadingM, data:dataM } = useQuery(GET_MARQUES, {
     variables: { company: company, role: role },
   });
  const [getCompanys, { loading, data }] = useLazyQuery(GET_COMPANYS,{
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [createAsset] = useMutation(CREATE_ASSET, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const usageoptions = [
     {
       value: "hr",
       label: t("hr"),
     },
     {
       value: "km",
       label: t("km"),
     },
   ];
   const workspaceoptions = [];
    fullworkspaces &&
      fullworkspaces.map((ele) => {
        console.log(typeof ele._id);
        if (workspaces && workspaces === ele._id) {
          workspaceoptions.push({
            value: ele._id,
            label: ele.name,
          });
         } else if(workspaces?.length<1) {
          workspaceoptions.push({
            value: ele._id,
            label: ele.name,
          });
         
        }
      });
  const companyoptions = [];
  role === "Super Admin" &&
    data &&
    data.getCompanys.map((ele) => {
      companyoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
  console.log(workspaceoptions);
  const catoptions = [];
  dataC &&
    dataC.getCategories.map((ele) => {
      catoptions.push({
        value: ele.name,
        label: ele.name,
      });
    });
  const marqueoptions = [];
  dataM &&
    dataM.getMarques.map((ele) => {
      marqueoptions.push({
        value: ele.name,
        label: ele.name,
      });
    });
  return (
    <>
      <Button
        type="primary"
        style={{ marginRight:'5px' }}
        onClick={() => {
          setOpen(true);
          getCompanys({ variables: { role: role } });
        }}
      >
        {isDesktop? <div><PlusOutlined /> {t("Add")}</div>:<div><PlusOutlined /></div>}
      </Button>
      <Modal
        open={open}
        title={t("FormValidations.9")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              if (comp === "" && role === "Super Admin") {
                setcompanystatus("error");
              } else {
                createAsset({
                  variables: {
                    serialNumber: values.serialNumber,
                    Model: values.Model,
                    Marque: values.Marque,
                    Categorie: values.Categorie,
                    hourMeter: parseInt(values.hourMeter),
                    odometer: parseInt(values.odometer),
                    Fuel: parseInt(values.fuel),
                    Source: values.Source,
                    usage: usage,
                    assetID: values.IMEI,
                    workspace: values.workspace ? (values.workspace==="All"? null: values.workspace):null,
                    company: comp === "" ? company : comp,
                    createdBy: _id,
                  },
                });
              }
              setOpen(false);

              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loading  ||loadingC || loadingM ? <Loading/>:(<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            workspace:workspaces,
          }}
        >
          <Form.Item
            name="serialNumber"
            label={t("InventoryTable.SerialNumber")}
            rules={[
              {
                required: true,
                message: t("FormValidations.1"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Marque"
            label={t("InventoryTable.Marque")}
            rules={[
              {
                required: true,
                message: t("FormValidations.2"),
              },
            ]}
          >
            <Select
                  style={{
                    width: "100%",
                  }}
                  options={marqueoptions}
                />
          </Form.Item>
          <Form.Item
            name="Model"
            label={t("InventoryTable.Model")}
            rules={[
              {
                required: true,
                message: t("FormValidations.3"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Categorie"
            label={t("InventoryTable.Category")}
            rules={[
              {
                required: true,
                message: t("FormValidations.4"),
              },
            ]}
          >
            <Select
                  style={{
                    width: "100%",
                  }}
                  options={catoptions}
                />
          </Form.Item>
          <Form.Item
            name="hourMeter"
            label={t("InventoryTable.Horametre")}
            initialValue={0}
            // rules={[
            //   {
            //     required: true,
            //     message: t("FormValidations.5"),
            //   },
            // ]}
          >
            <Input type="number" addonAfter={t("FormValidations.10")} pattern="^[0-9]+$"

 />
          </Form.Item>
          <Form.Item
            name="odometer"
            label={t("InventoryTable.Odometre")}
            initialValue={0}
          >
            <Input type="number" addonAfter={"Km"} />
          </Form.Item>
          <Form.Item
            name="fuel"
            label={t("Fuelconsumption")}
            initialValue={0}
          >
            <Input type="number" addonAfter=<Select
                  value={usage}
                  options={usageoptions}
                  onChange={(e) => {
                    setusage(e);
                  }}
                /> />
          </Form.Item>
          {role === "Super Admin" && (
            <>
              <p>Company</p>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                }}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={companyoptions}
                  status={companystatus}
                  onChange={(e) => {
                    setcomp(e);
                  }}
                />
              </Space>
            </>
          )}
          <Form.Item
            name="Source"
            label="Source"
            rules={[
              {
                required: true,
                message: t("FormValidations.6"),
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
              options={[{value: "DS1", label:"DS1"},
              {value: "Teltonika FMB140", label:"Teltonika FMB140"},
              {value: "Teltonika FMB920", label:"Teltonika FMB920"},]}
            />
          </Form.Item>
          <Form.Item
            name="IMEI"
            label="IMEI"
            rules={[
              {
                required: true,
                message: t("FormValidations.7"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {role !== "Super Admin" && (
              <Form.Item
                name="workspace"
                label={t("Workspace")}
                rules={[
              {
                required: true,
                message: t("FormValidations.49"),
              },
            ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={role !== "Admin" ? workspaceoptions : [{value: "All", label: companyname},...workspaceoptions]}
                />
              </Form.Item>
            )}
        </Form>)}
      </Modal>
    </>
  );
}
export default CreateAssetForm;
