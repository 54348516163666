import { React } from "react";
import { Layout,Tabs,Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../css/Administration.css";
import WorkspaceTab from "../../components/administration/WorkspaceTab.js";
const { Content } = Layout;
const { Title } = Typography;

function Workspace(props) {
  const { t } = useTranslation();
  const { role } = props.props;
  const items = [
    {
      key: "1",
      label: (
        <Link to={{ pathname: "/administration" }}>
          {" "}
          <div className="TabLink">{t("AdministrationTab.member")}</div>
        </Link>
      ),
    },
    role === "Admin" && {
      key: "2",
      label: <div className="TabLink">{t("AdministrationTab.workspace")}</div>,
      children: <WorkspaceTab props={props.props} />,
    },
    role === "Super Admin" && {
      key: "3",
      label: (
        <Link to={{ pathname: "/administration/company" }}>
          <div className="TabLink">{t("AdministrationTab.company")}</div>
        </Link>
      ),
    },
    (role === "Admin" || role === "AdminWorkspace") && {
      key: "4",
      label: (
        <Link to={{ pathname: "/administration/lists" }}>
          <div className="TabLink">{t("AdministrationTab.Category")}</div>
        </Link>
      ),
    },
  ];
  // role === "AdminWorkspace" && items.splice(-2, 2);
  // role === "Admin" && items.pop();

  return (
    <Content className="AdminisContent">
    <Title className="AdminisTitle" level={3}>{t("Administration.Admin")}</Title>
      <Tabs className="AdminisTabs" defaultActiveKey="2" items={items} />
    </Content>
  );
}
export default Workspace;
