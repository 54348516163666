/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Modal,
  Dropdown,
  Row,
  Col,
  notification,
  Card,
  List,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_WORKSPACES } from "../../queries/workspaceQuery.js";
import { DELETE_WORKSPACE } from "../../mutations/workspaceMutation.js";
import {
  SearchOutlined,
  EllipsisOutlined,ExclamationCircleFilled
} from "@ant-design/icons";
import "../../css/Inventory.css";
import dayjs from "dayjs";
import CreateWorkspaceForm from "../Forms/CreateWorkspaceForm.js";
// import Updateworkspace from "../Forms/UpdateWorkspace.js";
import Loading from "../../components/Loading/Loading.js";
import { ValueContext } from "../../context/Context.js";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
export default function WorkspaceTab(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const columns = [
    {
      title: t("workspace.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "created",
      key: "created",
    },
    {
      title: t("workspace.user"),
      dataIndex: "user",
      key: "user",
    },
    {
      title: t("workspace.equipment"),
      dataIndex: "equipment",
      key: "equipment",
    },
    {
      dataIndex: "action",
      key: "action",
    },
  ];
  const [searchInput, setSearchInput] = useState("");
  const { company, role } = props.props;
  const { loading, error, data } = useQuery(GET_WORKSPACES, {
    variables: { _id: company, role: role }
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.14"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("FormValidations.22"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.14"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteworkspace({ variables: { _id, role } });
        localStorage.setItem("nameworkspace", "Allspace")
        localStorage.setItem("worckID", JSON.stringify([]))
        window.location.reload();
      },
    });
  };
  const [deleteworkspace] = useMutation(DELETE_WORKSPACE, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_WORKSPACES,
        variables: { _id: company, role: role },
      },
    ],
  });
  if (loading) return <Loading />;
  if (error) return <></>;
  const TableData = data.getWorkspaces.filter((workspace) => {
    if (searchInput === "") {
      return workspace;
    } else if (workspace.name.toLowerCase().includes(searchInput)) {
      return workspace;
    }
  });
  const WorkspaceData = TableData.map((workspace) => {
    return {
      key: workspace._id,
      name: workspace.name,
      user: workspace.user.length,
      equipment: workspace.vehicle.length,
      created: dayjs(Math.floor(workspace.createdAt / 1000) * 1000).format(
        "DD MMM YYYY HH:mm"
      ),
      action: (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      type="text"
                      block
                      onClick={() => {
                        showDeleteConfirm(workspace._id);
                      }}
                    >
                      <Text type="danger">{t("DeleteWorkspace")}</Text>
                    </Button>
                  ),
                  key: "0",
                },
                // {
                //   label: (
                //     <Button
                //       type="text"
                //       block
                //       onClick={() => {
                //         showDeleteConfirm(workspace._id);
                //       }}
                //     >
                //       <Text>{t("DeleteWorkspace")}</Text>
                //     </Button>
                //   ),
                //   key: "1",
                // },
              ],
            }}
            trigger={["click"]}
          >
            <Button type="text" icon={<EllipsisOutlined />}></Button>
          </Dropdown>
        </>
      ),
    };
  });
  return (
    <Content
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      {isDesktop ? <Table
        columns={columns}
        size="small"
        dataSource={WorkspaceData}
        title={() => (
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("AlertsSearchInput")+" ("+WorkspaceData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                 <CreateWorkspaceForm props={props.props}/>
                </div>
            </Col>
        </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />:<List

      header={
        <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("AlertsSearchInput")+" ("+WorkspaceData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                <CreateWorkspaceForm props={props.props}/>
                </div>
            </Col>
        </Row>
      }
      pagination={{
        pageSize: 10,
        align:"start",
      }}
      itemLayout="horizontal"
      className="AlertList3"
      dataSource={WorkspaceData}
      renderItem={(item) => (
        <List.Item style={{margin:"5px 10px"}}>
         <div
                    key={
                      item.key
                    }
                    className="mydivouter2"
                  >
                <div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  top: "0px",
                  right: "10px",
                 }}>
                  <Text type="secondary">{item.created}</Text>
                </div>
                      <div style={{ 
                        position: "absolute",
                        zIndex: 2,
                        top: "35px",
                        right: "20px",
                       }}>
                        <Row>
                       {item.action}
                        </Row>
                      </div>
                    <Card style={{width: "100% ", height: "100%"}}   >
                  <div style={{margin:"20px 10px 10px 10px"}}>
                            <Row>
                              <Col></Col>
                              <Col><Text strong>{item.name}</Text></Col>
                            </Row>
                            <Row>
                              <Col><Text type="secondary">{t("workspace.user")}:</Text></Col>
                              <Col><Text type="secondary" style={{marginLeft:5}}>{item.user}</Text></Col>
                            </Row>
                            <Row>
                              <Col><Text type="secondary">{t("workspace.equipment")}:</Text></Col>
                              <Col><Text type="secondary" style={{marginLeft:5}}>{item.equipment}</Text></Col>
                            </Row>
                  </div>
                    <Row gutter={[16,16]} style={{ marginLeft:5,marginTop:7 }}>
                    </Row>
                    </Card>{" "}
                  </div>
        </List.Item>
      )}
    />}
    </Content>
  );
}
