/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Typography, Space, Card, InputNumber, Select, Button,Form,Row,Col,Input ,notification} from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_VEHICLES_FOR_ADMINISTRATION_PAGE } from "../../queries/mapQuery.js";
import { GET_USERS_FOR_ADMINISTRATION , GET_ADMINUSER} from "../../queries/UserQuery.js";
import { GET_ALERTS } from "../../queries/alertQuery.js";
import { ADD_ALERT_IDLE } from "../../mutations/alertMutations.js";
import Loading from "../../components/Loading/Loading.js";
import { Link } from "react-router-dom";
import { Button as MobileBtn } from "antd-mobile";
import "../../css/Alerts.css";
import { ValueContext } from "../../context/Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const { Content } = Layout;
const { Text } = Typography;

function IdelAlerts(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { company, role,_id,workspaces } = props.props;
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [days, setdays] = useState(0);
  const [name, setname] = useState();
  const [user, setUser] = useState([]);
  const [vehicle, setvehicle] = useState([]);
  const { isDesktop } = useContext(ValueContext);
  const  { loading: loadingv, error: errorv, data: datav } =
    useQuery(GET_VEHICLES_FOR_ADMINISTRATION_PAGE, {
      variables: { _id: company, role: role,workspace:workspaces },
    });
  const  { loading: loadinga, error: errora, data: dataa } =
    useQuery(GET_ADMINUSER, {
      variables: { _id: company},
    });
  const { loading: loadingu, error: erroru, data: datau } =
    useQuery(GET_USERS_FOR_ADMINISTRATION, {
      variables: { _id: company, role: role,workspace:workspaces },
    });
  const successNotification = () => {
    notification.open({
      description: t("Notification.16"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [addAlert] = useMutation(ADD_ALERT_IDLE,{
    refetchQueries: [{ query: GET_ALERTS, variables: { _id: company, role: role, user: _id } }],
    onCompleted: () => {
      successNotification();
    },
  });
  const submit = async (e) => {
    dataa.getAdminUser.map((element) => {
      if (!user.includes(element)) {
        user.push(element);
      }
    });
     if (!user.includes(_id)) {
       user.push(_id);
     }
    const {  error, data }= await addAlert({
      variables:
        { user, 
          vehicle: vehicle ? (vehicle.includes("All") ? (vehicleoptions.map(option => option.value)):vehicle): [],
          type: "Idle", limit:  ((hours * 3600) + (minutes*60)),  name, role,company,createdBy:_id }
  })
    if (data) {
      navigate("/alerts");
    } else {
      return <p>{ error }</p>
    }
  }
  if (loadinga) return <Loading />;
  if (errora) return <p> Error : {errora}</p>;
  if (loadingu) return <Loading/>
  if (erroru) return <p> Error : { erroru}</p>
  if (loadingv) return <Loading/>
  if (errorv) return <p> Error : { errorv}</p>

  const vehicleoptions = [];
   datav &&
     datav.getVehicles.map((ele) => {
       vehicleoptions.push({
         value: ele._id,
         label: ele.profile.Model + " / " + ele.profile.serialNumber,
       });
     });
   const useroptions = [];
   datau &&
     datau.getUsers.map((ele) => {
       useroptions.push({
         value: ele._id,
         label: ele.email,
       });
     });
  const selectProps = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    vehicle,
    options: [{value: "All", label: t("AllEquipment")},...vehicleoptions],
    onChange: (newValue) => {
      setvehicle(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.30"),
    maxTagCount: "responsive",
  };
  const selectProps2 = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    user,
    defaultValue: _id,
    options: useroptions,
    onChange: (newValue) => {
      setUser(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.14"),
    maxTagCount: "responsive",
  };
  

  return (
    <Content className="ActContent">
      {!isDesktop&&<MobileBtn className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </MobileBtn>}
      <Card
        title={t("Alerts.AlertRules")}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '650px',
          minWidth: '40vw',
          margin: 'auto',
        }}
      >
      <Form
      initialValues={{
        remember: true,
      }}
      onFinish={submit}
        >
          <div>
            <Text strong>{t("Administration.name")}</Text>
          </div>
          <Form.Item
            name="name"
      rules={[
        {
          required: true,
          message: t("FormValidations.34"),
        },
      ]}
    >
      <Input onChange={(e)=>{setname(e.target.value)}} style={{width:"70%"}} />
    </Form.Item>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Text strong>{t("Alerts.1")}</Text>
          <Row gutter={[5,5]}>
          <Col><Text>{t("Durée de ralentie est au dessus de ")} </Text></Col>
          <Col><Form.Item >
            <InputNumber min={0} defaultValue={0}  style={{ width: 50, }} onChange={(e)=>{setHours(e)}} />
            </Form.Item></Col>
          <Col><Text>{t("Alerts.3")}</Text></Col>
          <Col><Form.Item >
            <InputNumber min={0} defaultValue={0}  style={{ width: 50, }} onChange={(e)=>{setMinutes(e)}} />
            </Form.Item></Col>
          <Col><Text>{t("Alerts.4")}</Text></Col>

          </Row>

          <div>
            <Text strong>{t("Alerts.5")}</Text>
          </div>
          <div>
            <Text type="secondary">{t("Alerts.6")}</Text>
          </div>
            <Form.Item
            name="vehicle"
            rules={[
              {
                required: true,
                message: t("FormValidations.36"),
              },
            ]}
            >
          <Select {...selectProps} />
          </Form.Item>
          <div>
            <Text strong>{t("Alerts.13")}</Text>
          </div>
          <Form.Item >
          <Select {...selectProps2} />
          </Form.Item>
          <div style={{ marginTop:20 }}>
            <Space direction="horizontal">
              <Link to={{ pathname: "/alerts" }}>
                <Button>{t("Alerts.9")}</Button>
              </Link>
              <Form.Item >
              <Button type="primary" htmlType="submit">
              {t("Alerts.10")}
              </Button>
              </Form.Item>
            </Space>
          </div>
        </Space></Form>
      </Card>
    </Content>
  );
}
export default IdelAlerts;
