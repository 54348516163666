/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Form,
  Modal,
  Select,
  notification,
  Switch,
  Space,
} from "antd";
import { useMutation, useLazyQuery,useQuery } from "@apollo/client";
import {
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { GET_USERS_FOR_ADMINISTRATION,GET_ADMINUSER } from "../../queries/UserQuery.js";
import { UPDATE_MAINTENANCE } from "../../mutations/maintenanceMutations.js";
import { GET_MAINTENANCES } from "../../queries/maintenanceQuery.js";
import { convertToHoursMins } from "../../constants/ConvertHora.js";
import Loading from "../Loading/Loading.js";
function UpdateMaintenanceForm(props) {
  const { t } = useTranslation();
  const Maintenance= props.maintenance;
  const Mainvehicle=Maintenance.vehicle;
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.7"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const { company, role,_id,workspaces } = props.props;
  const [open, setOpen] = useState(false);
   const [user, setUser] = useState([]);
  const [form] = Form.useForm();
  const Echeance = Form.useWatch("echeance", form);
  const Echeance2 = Form.useWatch("echeance2", form);
  const option = Maintenance.echeance.includes("hr") ? "hr" : "klm";
  const showKlm = Maintenance.echeance.includes("hr") ? false : true;
  const showHr = Maintenance.echeance.includes("hr") ? true : false;
  const [getusers, { loading: loadingu, data: datau }] =
    useLazyQuery(GET_USERS_FOR_ADMINISTRATION, {
      variables: { _id: company, role: role,workspace:workspaces },
    });
    const  { loading: loadinga, error: errora, data: dataa } =
    useQuery(GET_ADMINUSER, {
      variables: { _id: company},
    });
  const [updateMaintenance] = useMutation(UPDATE_MAINTENANCE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_MAINTENANCES,
        variables:{user:_id,role:role},
      },
    ],
  });
  const useroptions = [];
  datau &&
    datau.getUsers.map((ele) => {
      useroptions.push({
        value: ele._id,
        label: ele.email,
      });
    });
  return (
    <>
      <Button
        type="text"
        block
        onClick={async() => {
          setOpen(true);
          getusers();
        }}
      >
        {t("Maintenance.14")}
      </Button>
      <Modal
        open={open}
        title={t("Maintenance.14")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setUser(values.members);
              dataa.getAdminUser.map((element) => {
                if (!user.includes(element)) {
                  user.push(element);
                }
              });
              if (!user.includes(_id)) {
                user.push(_id);
              }
              updateMaintenance({
                variables: {
                  _id:  Maintenance._id,  
                  name: values.name,
                  operations: values.operations,
                  echeance: option==="hr" ? values.echeance+"hr":option==="klm" ? values.echeance2+"km":"",
                  seuil: option==="hr" ? values.seuil:option==="klm" ? values.seuil2:"",
                  user: values.members,
                },
              });
              setOpen(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loadinga || loadingu ? <Loading/>:(<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            name: Maintenance.name,
            operations: Maintenance.operations.map((operation) =>{return operation}),
            echeance: option==="hr" ? parseInt(Maintenance.echeance.match(/\d+/g).join("")):null,
            echeance2: option==="klm" ? parseInt(Maintenance.echeance.match(/\d+/g).join("")):null,
            seuil: option==="hr" ? Maintenance.seuil:null,
            seuil2: option==="klm" ? Maintenance.seuil:null,
            members: role ==="AdminWorkspace" ? Maintenance.user.filter((user) =>{if(!dataa.getAdminUser.includes(user._id)){return user._id}}).map((ele)=>ele._id) : Maintenance.user.map((user) =>{return user._id}),
            equipment: Maintenance.vehicle._id,
          }}
        >
          <Form.Item
            name="name"
            label={t("Formeworkspace.lableforname")}
            rules={[
              {
                required: true,
                message: t("Maintenance.8"),
              },
            ]}
          >
            <Input placeholder={t("Formeworkspace.lableforname")} />
          </Form.Item>
          <Form.List name="operations">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? t("Maintenance.6"): ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t("Maintenance.9")
                    }
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={t("Maintenance.7")+" n°"+(index+1)}
                    style={{
                      width: "85%",
                      marginBottom: "10px",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 && index !== 0 ? (
                  <Button onClick={() => remove(field.name)}
                  style={{
                    width: '10%',
                    marginLeft : "10px",
  
                  }}
                  icon={<MinusOutlined />}></Button>
                ) : null}
                {index === 0 ? (
                  <Button
                    onClick={() => add()}
                    style={{
                      width: "10%",
                      marginLeft: "10px"
                    }}
                    icon={<PlusOutlined />}
                  ></Button>
                ) : null}
              </Form.Item>
            ))}
              <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
          <Form.Item
            name="equipment"
            label={t("Formeworkspace.lableforequipment")}
            rules={[
              {
                required: true,
                message: t("FormValidations.29"),
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              options={[{value: Maintenance.vehicle._id, label:Maintenance.vehicle.profile.Model+" / "+Maintenance.vehicle.profile.serialNumber}]}
            />
          </Form.Item>
          <Form.Item
            name="members"
            label={t("Formeworkspace.lableformembers")}
            rules={[
              {
                required: true,
                message: t("FormValidations.30"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              placeholder={t("SelectSearch")}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={useroptions}
            />
          </Form.Item>
          <Form.Item 
          name="switch"
          label="Type et paramétrage"
            rules={[
              {
            validator: async () => {
              if ( option === "") {
                return Promise.reject(new Error(t("Maintenance.10")));
              }
            },
          },
            ]}
          >
            <div style={{ marginBottom:"10px" }}>
              {Maintenance.echeance.includes("hr")&&(<Space size={"small"}>
                <Switch
                  size="small"
                  checked={option === "hr"}
                />
                <label htmlFor="hr">Temps d'usage:</label>
              </Space>)}
            </div>
            <div
              className="form-row"
              style={{ display: showHr ? "block" : "none" }}
            >
            <Form.Item>
              <Form.Item
                name="echeance"
                label={t("Maintenance.1")}
                rules={[
                  option==="hr"&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="hr" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="hactuel"
                label={t("Maintenance.2")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={ convertToHoursMins(Mainvehicle.hourMeter)}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="seuil"
                label={t("Maintenance.3")}
                rules={[
                  option==="hr"&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="hr" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={ Echeance ? convertToHoursMins(Echeance * 60 - Mainvehicle.hourMeter): "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
            </div>
            <div style={{ marginBottom:"10px" }}>
          {Maintenance.echeance.includes("km")&&(<Space size={"small"}>
            <Switch
              size="small"
              checked={option === "klm"}
            />
            <label htmlFor="klm">Kilométrage:</label>
          </Space>)}
          </div>
          <div
            className="form-row"
            style={{ display: showKlm ? "block" : "none" }}
          >
            <Form.Item>
              <Form.Item
                name="echeance2"
                label={t("Maintenance.1")}
                rules={[
                  option==="klm"&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="km" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="kactuel"
                label={t("Maintenance.5")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input addonAfter="km"
                  placeholder={parseFloat(Mainvehicle.odometer).toFixed(2)}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="seuil2"
                label={t("Maintenance.3")}
                rules={[
                  option==="klm"&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="km" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant2"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input addonAfter="km"
                  placeholder={Echeance2 ? (Echeance2 - parseFloat(Mainvehicle.odometer)).toFixed(2): "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
          </div>
          </Form.Item>
        </Form>)}
      </Modal>
    </>
  );
}
export default UpdateMaintenanceForm;
