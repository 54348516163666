/* eslint-disable array-callback-return */
import { React, useState,useContext,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import {
  Button,
  Input,
  Table,
  Layout,
  Modal,
  Typography,Dropdown, Row, Col
} from "antd";
import {
  SearchOutlined,ExclamationCircleFilled,EllipsisOutlined
} from "@ant-design/icons";
import NotificationDetails from "../Forms/NotificationDetails.js";
import NotificationsMobile from "./notificationsMobile.js";
import Loading from "../Loading/Loading.js";
import { GET_NOTIFICATIONS, GET_NOTREEDNOTIFICATIONS } from "../../queries/notificationQuery.js";
import { DELETE_NOTIFICATION } from "../../mutations/notificationMutation.js";
import { useQuery, useMutation } from "@apollo/client";
import { ValueContext} from "../../context/Context.js";
import dayjs from "dayjs";
import "../../css/Alerts.css";
const { Content } = Layout;
const { confirm } = Modal;
const { Text } = Typography;

function Notification(props) {
  const { value, setValue } = useContext(ValueContext);
  const { isDesktop } = useContext(ValueContext);
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const { company, role, _id } = props.props;
  const {loading, error,data,refetch} = useQuery(GET_NOTIFICATIONS, {
    variables: { _id: company, role: role ,user:_id},fetchPolicy: "no-cache",
  });
  const [deletenotification] = useMutation(DELETE_NOTIFICATION,{
    refetchQueries: [
      { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
      { query: GET_NOTREEDNOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  const socket = io({path: '/socket.io',transports: ['websocket'],});
  useEffect(() => {
   if (socket) {
     socket.on(_id+'alertNotification', () => {
       refetch();
     });
   }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [socket, data]);
  const columns = [
    {
      title: t("notification.name"),
      dataIndex: "name",
      key: "name",
    },
    
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("recue"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];
  if (loading) return <Loading />;
  if (error) return <></>;
  
  const showDeleteConfirm = (_id,status) => {
    confirm({
      title: t("FormValidations.31"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.32"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deletenotification({
                variables: { _id, role },
              });
              if (value > 0&&status==="Not Read") {
                setValue(value - 1);
                }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const TableData = data.getNotifications.filter((notification) => {
    if (searchInput === "") {
      return notification;
    } else if (notification.nameAlert.toLowerCase().includes(searchInput.toLowerCase())) {
      return notification;
    }
  });
  const NotificationData = TableData.map((notification) => {
    return {
      key: notification._id,
      date: dayjs(Math.floor(notification.createdAt / 1000) * 1000).format(
        "DD MMM YYYY HH:mm"
      ),
      type: t(notification.typeAlert+"2"),
      name: (
        <div className="InvDetBtn">
          <span>{notification.nameAlert}</span>
          {notification.status === "Not Read" && (
            <div class="new-box">{t("new")}</div>
          )}
          {notification.alert?.name&&<NotificationDetails props={props.props} notification={notification} />}
        </div>
      ),
      action: (
        <>
            <Dropdown 
              menu={{
                items: [
                  {
                    label: t("DeleteNotification"),
                    key: "1",
                    danger: true,
                  },
                ],
                onClick:({ key }) => {
                  switch(key) {
                    case "1":
                      showDeleteConfirm(notification._id,notification.status);
                      break;
                    default:
                      break;
                  }
                }
              }}
              autoAdjustOverflow
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
              trigger={["click"]}
            >
              <Button type="text" style={{width:100}} ><EllipsisOutlined /></Button>
            </Dropdown>
        </>
      ),
    };
  });
 if (role === "User") {
   columns.pop();
 }
  return (
    <Content className="AlertComponentContent">
      <>
      {isDesktop ? (
        <Table
        columns={columns}
        size="small"
        dataSource={NotificationData}
        title={() => (
          <div>
            <Input
              size="default"
              className="SearchBar"
              placeholder={t("AlertsSearchInput")+" ("+NotificationData.length+t("elements")+")"}
              prefix={<SearchOutlined />}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
      ):(
        <>
              <Row style={{margin:10}}>
          <Col span={12}>
          <Input
              size="default"
              className="SearchBar"
              style={{width:280}}
              placeholder={t("AlertsSearchInput")+" ("+NotificationData.length+t("elements")+")"}
              prefix={<SearchOutlined />}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            </Col>
        </Row>
        <NotificationsMobile props={props.props} NotificationData={TableData}/>
        </>
      
    )
      
      }
      </>
      
    </Content>
  );
}
export default Notification;
