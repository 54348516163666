/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup,LayersControl } from "react-leaflet";
import {
  Button,
  Modal,
  Descriptions,
  Col,
  Row,
  List,
  Typography,
} from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_GEOFENCE,GET_ALERT_ACTIVITY, GET_ALERT_SPEED, GET_ALERT_POWER, GET_ALERT_IDLE, } from "../../queries/alertQuery.js";
import Loading from "../Loading/Loading.js";
import { convertToHoursMins,convertToDaysHoursMins } from "../../constants/ConvertHora.js";
const { Text } = Typography;
function AlertDetails(props) {
  const { t } = useTranslation();
  const [getGeofence,{data,error,loading}]= useLazyQuery(GET_GEOFENCE);
  const [getAlert_activity,{data:dataa,error:errora,loading:loadinga}]= useLazyQuery(GET_ALERT_ACTIVITY);
  const [getAlert_speed,{data:dataaa,error:erroraa,loading:loadingaa}]= useLazyQuery(GET_ALERT_SPEED);
  const [getAlert_power,{data:dataaaa,error:erroraaa,loading:loadingaaa}]= useLazyQuery(GET_ALERT_POWER);
  const [getAlert_idel,{data:dataaaaa,error:erroraaaa,loading:loadingaaaa}]= useLazyQuery(GET_ALERT_IDLE);
  const alert= props.alert;
  let vehicleoptions = [];
  let vehicleoptions2 = [];
  data &&
  data.getGeofence.vehicles.map((vehicle)=>{
      vehicleoptions.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  dataa &&
  dataa.getAlert_activity.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  dataaa &&
  dataaa.getAlert_speed.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  dataaaa &&
  dataaaa.getAlert_power.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  dataaaaa &&
  dataaaaa.getAlert_idle.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
    let useroptions = [];
    props.alert.user.map((user)=>{
      useroptions.push({
          value: user._id,
          label: user.fname + "  " + user.lname,
        });
      });
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={async() => {
          setOpen(true);
          if(alert.type==="Geofence"){
            await getGeofence({ variables: { _id:alert.geofence._id } });
          }else if(alert.type==="Activity"){
            await getAlert_activity({ variables: { _id:alert.activity._id } });
          }else if(alert.type==="Inactivity"){
            await getAlert_activity({ variables: { _id:alert.inactivity._id } });
          }else if(alert.type==="speed"){
            await getAlert_speed({ variables: { _id:alert.speed._id } });
          }else if(alert.type==="power"){
            await getAlert_power({ variables: { _id:alert.power._id } });
          }else if(alert.type==="Idle"){
            await getAlert_idel({ variables: { _id:alert.idle._id } });
          }
        }}
      >
        {t("Details")}
      </Button>
      <Modal
        open={open}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
            setOpen(false);
        }}
      >
        {loading || loadinga || loadingaa || loadingaaa || loadingaaaa ? <Loading/>:(<>{alert.type==="Geofence"&&data&&(<Descriptions labelStyle={{ color:"black" }} title={t("Description")} column={1}>
        
        <Row>
            <Col span={24}>
            <MapContainer
              // zoom={16}
              scrollWheelZoom={true}
              style={{ height: "250px", width: "100%" }}
              bounds={data.getGeofence.coordinates}
            >
              <LayersControl position="topright">
                <LayerGroup>
                  <LayersControl.BaseLayer checked name="Simple">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
                </LayerGroup>
              </LayersControl>
              <Polygon positions={data.getGeofence.coordinates} />
            </MapContainer>
            </Col>
        </Row><Row>
            <Col span={5}>Condition :</Col>
            <Col>{data.getGeofence.condition==="Out" ? t("Alerts.22"):(data.getGeofence.condition==="In" ? t("Alerts.21") : t("InAndOut")) }</Col>
        </Row>
        <Row>
            <Col span={5}>{t("Alerts.13")}</Col>
            <Col span={19}>
            <List
    itemLayout="horizontal"
    className="HistoryList"
    style={{ height:"10vh" }}
    dataSource={useroptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        <Row>
            <Col span={5}>{t("Formeworkspace.lableforequipment")}</Col>
            <Col span={19}>
            <List
    itemLayout="horizontal"
    className="HistoryList"
    style={{ height:"20vh" }}
    dataSource={vehicleoptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        </Descriptions>)}
        {alert.type!=="Geofence"&&(dataa || dataaa || dataaaa || dataaaaa)&&(<Descriptions labelStyle={{ color:"black" }} title="Description" column={1}>
        <Row>
            {alert.type!=="power"&&<Col span={5}>Condition :</Col>}
            {(alert.type==="Activity" || alert.type==="Inactivity")&&(<Col>{alert.type==="Activity" ? t("Alerts.28"):t("Alerts.27")}{alert.type==="Activity" ?dataa.getAlert_activity.option==="hr"? convertToHoursMins(dataa.getAlert_activity.limit):dataa.getAlert_activity.limit +" Km" : convertToDaysHoursMins(dataa.getAlert_activity.limit)}</Col>)}
            {alert.type==="speed"&&(<Col>{t("alerter si le vitesse dépasse")+" "+dataaa.getAlert_speed.speedLimit+" Km/h "+t("pendant")+" "+dataaa.getAlert_speed.limit+" min"}</Col>)}
            {alert.type==="Idle"&&(<Col>{t("alerter si ralenti dépasse")+" "+convertToHoursMins(dataaaaa.getAlert_idle.limit/60)}</Col>)}
        </Row>
        {alert.type==="Activity"&&(<Row>
            <Col span={5}>{t("Alerts.29")}</Col>
            <Col>{dataa.getAlert_activity.period.map((day,index) => {
                return t(day)+((index<(dataa.getAlert_activity.period.length-1)) ? " , ":"")
            })}</Col>
        </Row>)}
        <Row>
            <Col span={5}>{t("Alerts.13")}</Col>
            <Col span={19}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"15vh",overflow:"auto" }}
    dataSource={useroptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        <Row>
            <Col span={5}>{t("Formeworkspace.lableforequipment")}</Col>
            <Col span={19}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"20vh",overflow:"auto" }}
    dataSource={vehicleoptions2}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        </Descriptions>)}</>)}
        {error&&<Text type='danger'>{error.message}</Text>}
        {errora&&<Text type='danger'>{errora.message}</Text>}
        {erroraa&&<Text type='danger'>{erroraa.message}</Text>}
        {erroraaa&&<Text type='danger'>{erroraaa.message}</Text>}
        {erroraaaa&&<Text type='danger'>{erroraaaa.message}</Text>}
      </Modal>
    </>
  );
}
export default AlertDetails;
