import { React, lazy } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";
import { GET_ZONE } from "../../queries/zoneQuery.js";
import "../../css/ProfileTabs.css";
import Breadcrumbs from "../../components/Multiple/BreadcrumbsZone.js";
import { useQuery } from "@apollo/client";
import Loading from "../../components/Loading/Loading.js";
const ZoneMember = lazy(() => import("../../components/Zone/ZoneMember.js"));
const { Content } = Layout;

function MemberZone(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const zone = location.state.zone;
  const path = location.state.path;
  const { loading, error, data } = useQuery(GET_ZONE, {
    variables: { _id: zone._id , role:props.props.role},
  });
  const items = [
    {
      key: "1",
      label: (
        <Link to={{ pathname: "/apercu" }} state={{ zone, path }}>
          <div className="TabLink">{t("ZoneTabs.insight")}</div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={{ pathname: "/chronologie" }} state={{ zone, path }}>
          <div className="TabLink">{t("ZoneTabs.chronology")}</div>
        </Link>
      ),
    },
    {
      key: "3",
      label: <div className="TabLink">{t("AdministrationTab.member")}</div>,
        children: <ZoneMember data={data} zone={zone} props={props} />,
    },
  ];

  if (loading) return <Loading />;
  if (error) return <></>;
  return (
    <Content
      style={{
        height: "100vh",
        width: "100wh",
        padding: "30px 20px 20px 30px",
        overflowY: "scroll",
      }}
    >
      <Breadcrumbs data={data} path={path} props={props} />

      <Tabs defaultActiveKey="3" items={items} />
    </Content>
  );
}
export default MemberZone;
