/* eslint-disable array-callback-return */
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup,LayersControl } from "react-leaflet";
import { Descriptions, Layout, List, Modal } from "antd";
import { Typography,Card,Row,Col} from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_ALERT } from "../../mutations/alertMutations.js";
import { GET_GEOFENCE,GET_ALERT_ACTIVITY, GET_ALERT_SPEED, GET_ALERT_POWER, GET_ALERT_IDLE, GET_ALERT_CRUSH, GET_ALERT_TOWING, GET_ALERTS } from "../../queries/alertQuery.js";
import Loading from "../../components/Loading/Loading.js";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/Alerts.css";
import { convertToDaysHoursMins, convertToHoursMins } from "../../constants/ConvertHora.js";
import { Button } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GET_NOTIFICATIONS, GET_NOTREEDNOTIFICATIONS } from "../../queries/notificationQuery.js";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const { Content } = Layout;
const { Text } = Typography;

function AlertsDetailsMobile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let location = useLocation();
    const alert = location.state.alert;
    const { company, role, _id } = location.state.props.props;
    const [deletealert] = useMutation(DELETE_ALERT, {
      refetchQueries: [
        { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
        { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
        { query: GET_NOTREEDNOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
      ],
    });
    const [getGeofence,{data,error,loading}]= useLazyQuery(GET_GEOFENCE);
    const [getAlert_activity,{data:data2,error:error2,loading:loading2}]= useLazyQuery(GET_ALERT_ACTIVITY);
    const [getAlert_speed,{data:data3,error:error3,loading:loading3}]= useLazyQuery(GET_ALERT_SPEED);
    const [getAlert_power,{data:data4,error:error4,loading:loading4}]= useLazyQuery(GET_ALERT_POWER);
    const [getAlert_idel,{data:data5,error:error5,loading:loading5}]= useLazyQuery(GET_ALERT_IDLE);
    const [getAlert_crush,{data:data6,error:error6,loading:loading6}]= useLazyQuery(GET_ALERT_CRUSH);
    const [getAlert_towing,{data:data7,error:error7,loading:loading7}]= useLazyQuery(GET_ALERT_TOWING);

    useEffect(() => {
        if (alert.type === "Geofence") {
            getGeofence({ variables: { _id: alert.geofence._id } });
        } else if (alert.type === "Activity") {
            getAlert_activity({ variables: { _id: alert.activity._id } });
        } else if (alert.type === "Inactivity") {
            getAlert_activity({ variables: { _id: alert.inactivity._id } });
        } else if (alert.type === "speed") {
            getAlert_speed({ variables: { _id: alert.speed._id } });
        } else if (alert.type === "power") {
            getAlert_power({ variables: { _id: alert.power._id } });
        } else if (alert.type === "Idle") {
            getAlert_idel({ variables: { _id: alert.idle._id } });
        } else if (alert.type === "crush") {
          getAlert_crush({ variables: { _id: alert.crush._id } });
        } else if (alert.type === "towing") {
          getAlert_towing({ variables: { _id: alert.towing._id } });
        }
    }, [alert, getGeofence, getAlert_activity, getAlert_speed, getAlert_power, getAlert_idel,getAlert_crush,getAlert_towing]);

    const showDeleteConfirm = (_id) => {
      confirm({
        title: t("FormValidations.33"),
        icon: <ExclamationCircleFilled />,
        content: t("FormValidations.14"),
        okText: t("FormValidations.11"),
        okType: "danger",
        cancelText: t("FormValidations.12"),
        onOk() {
          deletealert({ variables: { _id, role } });
          navigate(-1);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };

      let vehicleoptions = [];
  let vehicleoptions2 = [];
  data &&
  data.getGeofence.vehicles.map((vehicle)=>{
      vehicleoptions.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data2 &&
  data2.getAlert_activity.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data3 &&
  data3.getAlert_speed.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data4 &&
  data4.getAlert_power.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data5 &&
  data5.getAlert_idle.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data6 &&
  data6.getAlert_crush.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
  data7 &&
  data7.getAlert_towing.vehicle.map((vehicle)=>{
    vehicleoptions2.push({
        value: vehicle._id,
        label: vehicle.profile.Model + " / " + vehicle.profile.serialNumber,
      });
    });
    let useroptions = [];
        alert && alert.user.map((user)=>{
          useroptions.push({
              value: user._id,
              label: user.fname + "  " + user.lname,
            });
          });

  return (
    <Content className="AlertDetailsContent">
      <Button className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
      <Card
        title={t("Alerts.AlertRules")}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '650px',
          margin: 'auto',
        }}
      >
      {loading ||  loading2 || loading3 || loading4 || loading5 || loading6 || loading7 ? <Loading/>:(<>{alert?.type==="Geofence"&&data&&(<Descriptions labelStyle={{ color:"black" }} title={t("Description")} column={1}>
        
        <Row>
            <Col span={24}>
            <MapContainer
              // zoom={16}
              scrollWheelZoom={true}
              style={{ height: "250px", width: "100%" }}
              bounds={data.getGeofence.coordinates}
            >
              <LayersControl position="topright">
                <LayerGroup>
                  <LayersControl.BaseLayer checked name="Simple">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
                </LayerGroup>
              </LayersControl>
              <Polygon positions={data.getGeofence.coordinates} />
            </MapContainer>
            </Col>
        </Row><Row>
            <Col span={7}>Condition :</Col>
            <Col>{data.getGeofence.condition==="Out" ? t("Alerts.22"):(data.getGeofence.condition==="In" ? t("Alerts.21") : t("InAndOut")) }</Col>
        </Row>
        <Row>
            <Col span={7}>{t("Alerts.13")}</Col>
            <Col span={17}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"15vh",overflow:"auto" }}
    dataSource={useroptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        <Row>
            <Col span={7}>{t("Formeworkspace.lableforequipment")}</Col>
            <Col span={17}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"20vh",overflow:"auto" }}
    dataSource={vehicleoptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        </Descriptions>)}
        {alert?.type!=="Geofence"&&(data2 || data3 || data4 || data5 || data6 || data7 )&&(<Descriptions labelStyle={{ color:"black" }} title="Description" column={1}>
        <Row>
            <Col span={7}>Condition :</Col>
            {(alert?.type==="Activity" || alert?.type==="Inactivity")&&(<Col>{alert?.type==="Activity" ? t("Alerts.28"):t("Alerts.27")}{alert?.type==="Activity" ?data2.getAlert_activity.option==="hr"? convertToHoursMins(data2.getAlert_activity.limit):data2.getAlert_activity.limit +" Km" : convertToDaysHoursMins(data2.getAlert_activity.limit)}</Col>)}
            {alert?.type==="speed"&&(<Col>{t("Alerter si le vitesse dépasse")+" "+data3.getAlert_speed.speedLimit+" Km/h "+t("pendant")+" "+data3.getAlert_speed.limit+" min"}</Col>)}
            {alert.type==="Idle"&&(<Col>{t("Alerter si ralenti dépasse")+" "+convertToHoursMins(data5.getAlert_idle.limit/60)}</Col>)}
            {alert.type==="crush"&&(<Col>{t("Il y a possibilité d'accident")}</Col>)}
            {alert.type==="towing"&&(<Col>{t("Il y a détection de remorquage")}</Col>)}
            {alert.type==="power"&&(<Col>{t("Alerts.33")}</Col>)}
        </Row>
        {alert?.type==="Activity"&&(<Row>
            <Col span={7}>{t("Alerts.29")}</Col>
            <Col>{data2.getAlert_activity.period.map((day,index) => {
                return t(day)+((index<(data2.getAlert_activity.period.length-1)) ? " , ":"")
            })}</Col>
        </Row>)}
        <Row>
            <Col span={7}>{t("Alerts.13")}</Col>
            <Col span={17}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"20vh",overflow:"auto" }}
    dataSource={useroptions}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        <Row>
            <Col span={7}>{t("Formeworkspace.lableforequipment")}</Col>
            <Col span={17}>
            <List
    itemLayout="horizontal"
    style={{ maxHeight:"20vh",overflow:"auto" }}
    dataSource={vehicleoptions2}
    renderItem={(item) => (
      <List.Item>
       {item.label}
      </List.Item>
    )}
  />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
            <Button style={{padding:0}} color='danger' fill='none' onClick={()=>{showDeleteConfirm(alert._id);}}>
            Supprimer cette alert
          </Button>
          </Col>
        </Row>
        </Descriptions>)}</>)}
        {error&&<Text type='danger'>{error.message}</Text>}
        {error2&&<Text type='danger'>{error2.message}</Text>}
        {error3&&<Text type='danger'>{error3.message}</Text>}
        {error4&&<Text type='danger'>{error4.message}</Text>}
        {error5&&<Text type='danger'>{error5.message}</Text>}
        {error6&&<Text type='danger'>{error6.message}</Text>}
        {error7&&<Text type='danger'>{error7.message}</Text>}
      </Card>
    </Content>
  );
}
export default AlertsDetailsMobile;
