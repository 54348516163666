import React, { useCallback, useEffect ,useState,lazy, Suspense, useContext  } from 'react';
import { Route, Routes } from 'react-router-dom'
import { Navigate } from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import CustomEmpty from '../Multiple/CustomEmpty.js';
import {ConfigProvider } from 'antd';
import { useMappedState } from 'redux-react-hook';
import '../../css/App.css';
import useWithAuthenticate from '../WithAuthenticate/index.js';
import Dashbord from '../Home/Dashbord.js';
import AlertsDetailsMobile from '../../pages/Alerts/AlertsDetailsMobile.js';
import NotificationsDetailsMobile from '../../pages/Alerts/NotificationsDetailsMobile.js';
import NonAuthHome from '../Home/nonauth.js';
import Loading from '../Loading/Loading.js';
import Administration from '../../pages/administrationTab/Administration.js';
import Workspace from '../../pages/administrationTab/workspace.js';
import Company from '../../pages/administrationTab/company.js';
import MarqueCat from "../../pages/administrationTab/MarqueCat.js";
import NotAuthorized from '../../pages/ErrorPages/NotAuthorized.js';
import NotificationsTab from '../../pages/Alerts/notificationTab.js';
import {  ValueContext, ValueProvider } from "../../context/Context.js";
import Login from '../../pages/Auth/Login.js';
import Signup from '../../pages/Auth/Signup.js';
import NotFound from '../NotFound/index.js';
import Map from '../../pages/Map.js';
import Inventory from '../../pages/Inventory.js';
import Details from '../../pages/Mobile/Details.js';
import MobileUtilisation from '../../pages/Mobile/MobileUtilisation.js';
import MobileHistory from '../../pages/Mobile/MobileHistory.js';
import Zone from '../../pages/Zone.js';
import Maintenance from '../../pages/Maintenance.js';
import Profile from '../../pages/Profile.js';
import Alerts from '../../pages/Alerts/Alerts.js';
import ActivityAlerts from '../../pages/Alerts/ActivityAlerts.js';
import InactivityAlerts from '../../pages/Alerts/InactivityAlerts.js';
import GeofencingAlerts from '../../pages/Alerts/GeofencingAlerts.js';
import FuelLevelAlerts from '../../pages/Alerts/FuelLevelAlerts.js';
import SpeedAlerts from '../../pages/Alerts/SpeedAlerts.js';
import PowerCutAlerts from '../../pages/Alerts/PowerCutAlerts.js';
import CrushAlerts from '../../pages/Alerts/CrushAlerts.js';
import TowingAlerts from '../../pages/Alerts/TowingAlerts.js';
import IdelAlerts from '../../pages/Alerts/IdelAlerts.js';
import VehicleProfile from '../../pages/ProfileTabs/VehicleProfile.js';
import ZoneAprecu from "../../pages/zoneTab/ZoneAprecu.js";
import ZoneChronologie from "../../pages/zoneTab/ZoneChronologie.js";
import MemberZone from "../../pages/zoneTab/MemberZone.js";
import VehicleLive from '../../pages/ProfileTabs/VehicleLive.js';
import VehicleHistorique from '../../pages/ProfileTabs/VehicleHistorique.js';
// import VehicleHistoriqueRoute from '../../pages/ProfileTabs/VehicleHistoriqueRoute.js';
import VehicleUtilisation from '../../pages/ProfileTabs/VehicleUtilisation.js';
import CreateZone from '../../pages/CreateZone.js';
import ResetPassword from '../../pages/Auth/ResetPassword.js';
import SendResetPassword from '../../pages/Auth/SendResetPassword.js';
import CarbConso from '../../pages/Reports/CarbConso.js';
import CarbReful from '../../pages/Reports/CarbReful.js';
import IdelReports from '../../pages/Reports/IdelReports.js';
import CarbVol from '../../pages/Reports/CarbVol.js';
import CarbConsoDetails from '../../pages/Reports/CarbConsoDetails.js';
import CarbRefulDetails from '../../pages/Reports/CarbRefulDetails.js';
import CarbVolDetails from '../../pages/Reports/CarbVolDetails.js';
import CreateAssetMobile from '../../pages/Mobile/Forms/Asset/CreateAssetMobile.js';
import UpdateAssetMobile from '../../pages/Mobile/Forms/Asset/UpdateAssetMobile.js';
const VehicleHistoriqueRoute = lazy(() => import('../../pages/ProfileTabs/VehicleHistoriqueRoute.js'));


const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        profile: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        getWorkspaces: {
          keyArgs: ["_id", "role"],
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});
const token = localStorage.getItem('token');
const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_URL || "/graphql",
  cache,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

function App() {
  useWithAuthenticate();
  const { isDesktop } = useContext(ValueContext);
  const mapState = useCallback((state) => ({
    loading: state.sessionState.loading,
    authUser: state.sessionState.authUser
  }), [])
  const { loading,authUser } = useMappedState(mapState);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Refresh the page
      window.location.reload();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // This function will be executed when the component is unmounted
    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);// The empty dependency array ensures the cleanup runs only on unmount
  const [AuthUserModified, setAuthUserModified] = useState();
const changeAuthUser = (newAuthUser) => {
  // Update the authUser in the state here
  // You can use your state management mechanism here
  setAuthUserModified(newAuthUser);
};

  if (!loading) return (
    <ApolloProvider client={client}>
      <ValueProvider>
      <ConfigProvider renderEmpty={CustomEmpty}>
      <div className="App">
        <Routes>
            <Route exact path="/alertDetails" element={<AlertsDetailsMobile props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/notificationDetails" element={<NotificationsDetailsMobile props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/details" element={<Details props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/MobileUtilisation" element={<MobileUtilisation props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/MobileHistory" element={<MobileHistory props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/CreateAssetMobile" element={<CreateAssetMobile props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/UpdateAssetMobile" element={<UpdateAssetMobile props={AuthUserModified?AuthUserModified:authUser}/>} />
            <Route exact path="/" element={authUser ? <Dashbord props={AuthUserModified?AuthUserModified:authUser} changeAuthUser={changeAuthUser} />:(isDesktop ? <NonAuthHome />:<Navigate to="/login" replace={true} />)}>
              <Route exact path="" element={<Navigate to="/map" replace={true} />} />
              <Route exact path="loading" element={<Loading/>} />
              <Route exact path="map" element={<Map props={AuthUserModified?AuthUserModified:authUser} />} />
              <Route exact path="inventory" element={<Inventory client={client} props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="zone" element={<Zone client={client} props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="user" element={<Profile props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="profile" element={<VehicleProfile props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="apercu" element={<ZoneAprecu props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="chronologie" element={<ZoneChronologie props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="member" element={<MemberZone props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="live" element={<VehicleLive props={AuthUserModified?AuthUserModified:authUser} client={client}/>} />
              <Route exact path="history" element={<VehicleHistorique props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="history/route" element={<Suspense fallback={<Loading />}><VehicleHistoriqueRoute /></Suspense>} />
              <Route exact path="utilisation" element={<VehicleUtilisation props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="alerts" element={<Alerts props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="alerts/notification" element={<NotificationsTab props={AuthUserModified?AuthUserModified:authUser}/>} />               
              <Route exact path="maintenance" element={<Maintenance props={AuthUserModified?AuthUserModified:authUser}/>} />
              <Route exact path="/Carb-Conso" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbConso props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/Carb-Conso/details" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbConsoDetails props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/Carb-Reful" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbReful props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/Carb-Reful/details" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbRefulDetails props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
                <Route exact path="/IdelReports" element={
                  authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                  <IdelReports props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/Carb-Vol" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbVol props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/Carb-Vol/details" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CarbVolDetails props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/administration" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <Administration props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/alerts/activity" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <ActivityAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/inactivity" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <InactivityAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/geofencing" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <GeofencingAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/Fuel-Level" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <FuelLevelAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/speed" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <SpeedAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/power-cut" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <PowerCutAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/crush" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CrushAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/towing" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <TowingAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="alerts/idel" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <IdelAlerts props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="zone/create" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <CreateZone props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/administration/lists" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" || authUser.role === "AdminWorkspace") ?
                <MarqueCat props={AuthUserModified?AuthUserModified:authUser} />:<NotAuthorized/>} />
              <Route exact path="/administration/workspace" element={
                authUser && (authUser.role === "Super Admin" || authUser.role === "Admin" ) ?
                <Workspace props={AuthUserModified?AuthUserModified:authUser}/>:<NotAuthorized/>} />              
              <Route exact path="/administration/company" element={
                authUser && (authUser.role === "Super Admin" ) ?
                <Company props={AuthUserModified?AuthUserModified:authUser}/>:<NotAuthorized/>} />              
            </Route>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset-password" element={<SendResetPassword />} />
            <Route exact path="/reset-password/:token" element={<ResetPassword />} />
            <Route exact path="/accept-invitation/:token" element={<Signup />} />
            <Route component={NotFound} />
          </Routes>
          
          

      </div>
    </ConfigProvider>
      </ValueProvider>
    </ApolloProvider>
  );
}

export default App;
