import React,{useRef, useState} from 'react'
import { Layout,Col, Row,Typography,Timeline, } from "antd";
import { useTranslation } from "react-i18next";
import Loading from '../../components/Loading/Loading.js';
import dayjs from "dayjs";
import {  useLocation,useNavigate } from "react-router-dom";
import HistoriqueMap from "../../components/map/HistoriqueMapMobile.js";
import { GET_HISTORYBYDATE } from '../../queries/mapQuery.js';
import { useQuery } from '@apollo/client';
import "../../css/Mobile.css"
import { Button, List, CalendarPickerView, Card, Popup, FloatingPanel } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClockRotateLeft, faFlagCheckered, faSquareParking } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { convertSecondsToDynamicTime } from '../../constants/ConvertHora.js';
const { Text } = Typography;
const { Content } = Layout; 
const anchors = [window.innerHeight*0.35 , window.innerHeight * 0.35, window.innerHeight ]
const Time = (date_1, date_2) =>{
  let difference = date_1 - date_2;
  let Minute = (Math.floor(difference/1000)/60);
  let HH = Math.floor(Minute/60);
  if(HH<10) HH="0"+HH;
  let MM = Math.floor(Minute%60);
  if(MM<10) MM="0"+MM;
  return HH+" h "+MM+" min";
}
function MobileHistory(props) {
  const calendarRef = useRef(null);
  let { state } = useLocation();
  let vehicle = state.vehicle;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  let [period, setperiod] = useState([dayjs().toDate(),dayjs().toDate(),]);
  let [Newperiod, setNewperiod] = useState([dayjs().toDate(),dayjs().toDate(),]);
  let { loading, error, data  } = useQuery(GET_HISTORYBYDATE, { variables: { vehicle: vehicle._id ,period:period  } });
  let trips_data = []
  let bearingsarr1 = []
  if (loading) return <Loading/>;
  if (error) return <></>;
  let bearing = (params) => {
    let [start_latitude, start_longitude] = params[0];
  let [stop_latitude, stop_longitude] = params[1];

  let y = Math.sin(stop_longitude - start_longitude) * Math.cos(stop_latitude);
  let x = Math.cos(start_latitude) * Math.sin(stop_latitude) - Math.sin(start_latitude) * Math.cos(stop_latitude) * Math.cos(stop_longitude - start_longitude);
  let brng = Math.atan2(y, x) * 180 / Math.PI;

  return brng;
};
  trips_data = [...data.getTrip_HistoryByDate.trips];
  let Tabledata = data.getTrip_HistoryByDate.trips.flatMap((trip, i) => {
    
    return[
      {
        color: 'green',
        children: <Row>
          <Row><Text >{trip.start_address}</Text></Row>
          <Row><Text type='secondary'>{"Départ : "+dayjs(Math.floor(trip.start_time/1000)*1000).format("HH:mm DD MMM YYYY")}</Text></Row>
        </Row>,
      },
      {
        color: 'black',
        children: <Row>
        <Row><Text >{trip.end_address}</Text></Row>
        <Row style={{width:"100%"}}>
          <Col span={24}><Text type='secondary'>{"Arrivé : "+dayjs(Math.floor(trip.end_time/1000)*1000).format("HH:mm DD MMM YYYY")}</Text></Col>
          <Col span={24}><Text type='secondary'>{"Durée : "+trip.duration}</Text></Col>
          <Col span={24}><Text type='secondary'>{"Distance : "+trip.distance}</Text></Col>
        </Row>
      </Row>,
      dot:<FontAwesomeIcon icon={faFlagCheckered} />,
    },
    (i!==(trips_data.length-1))&&{
      color: 'blue',
      children: <>{Time(trips_data[i+1].start_time,trips_data[i].end_time)+" "}<FontAwesomeIcon icon={faClock} /></>,
      dot:<FontAwesomeIcon icon={faSquareParking} />,
    },
  ]});
  return (
    <>
    <Content className='HistoryTabContent'>
    <div >
    <Button className='RHistoryBtn' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button className='HistoryBtn' size='mini' 
          onClick={()=>{
            setVisible(true);
            }} >
          <FontAwesomeIcon icon={faClockRotateLeft} />
          </Button>
          <Popup
              visible={visible}
              showCloseButton
              className='calendarPopup'
              afterShow={()=>{calendarRef.current.jumpToToday();}}
              onMaskClick={() => {
                setVisible(false)
              }}
              style={{}}
              onClose={() => {
                setVisible(false)
              }}
              bodyStyle={{ height: '80vh' }}
            >
              <CalendarPickerView style={{margin:10,overflowY:'hidden'}} defaultValue={period} ref={calendarRef} selectionMode='range'  weekStartsOn='Monday' min={dayjs().subtract(90, 'day').toDate()} max={dayjs().toDate()}  onChange={val => {
                  setNewperiod(val);  }} 
                    />
              <Button style={{width:"80vw",margin:"auto"}} onClick={()=>{setperiod(Newperiod);setVisible(false)}} block color='primary' size='large'>
              Confirmer
              </Button>
            </Popup>
        <HistoriqueMap 
            bearingsarr1={bearingsarr1}
            trips_data={trips_data} 
        vehicle={state.data.getVehicle}
        props={props}
        />
        </div>
        <FloatingPanel anchors={anchors} >
        <div >
        <Card className='HCard' title='Géneral' >
        <List >
        <List.Item extra={data.getTrip_HistoryByDate.totalDistance.toFixed(2)+" km"}>Longueur du trajet</List.Item>
        <List.Item extra={convertSecondsToDynamicTime(Math.floor((data.getTrip_HistoryByDate.totalTime)/1000))}>Durée de Mouvement</List.Item>
        <List.Item extra={convertSecondsToDynamicTime(data.getTrip_HistoryByDate.stop_time)}>Durée d'Arrêt  </List.Item>
        <List.Item extra={data.getTrip_HistoryByDate.max_speed+" Km/h"}>Vitesse maximale </List.Item>
        <List.Item extra={data.getTrip_HistoryByDate.avg_moy_speed.toFixed(2)+" Km/h"}>Vitesse moyenne</List.Item>
      </List>
        </Card>
        <Card style={{marginBottom:50}}>
        <Timeline
          items={Tabledata}
        />
        </Card>
        </div>
      </FloatingPanel>
    </Content>
    </>
  );
}
export default MobileHistory;