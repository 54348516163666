import React from "react";
import { Layout, Typography, Carousel, Row, Col, Card,Image } from "antd";
import "../../css/HomePage.css";
import image1 from "../../images/1.jpg";
import image2 from "../../images/2.jpg";
import image3 from "../../images/3.jpg";
import image4 from "../../images/4.jpg";
import image5 from "../../images/5.jpg";
import image6 from "../../images/6.jpg";
import image7 from "../../images/7.jpg";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckOutlined } from "@ant-design/icons";
import enUS from "antd-mobile/es/locales/en-US.js";
import { setDefaultConfig } from "antd-mobile";
import frLan from "../../constants/fr-Lan.js";
const { Title } = Typography;

const { Header,Content } = Layout;
function NonAuthHome() {
  const { t, i18n } = useTranslation();
  const changeLanguage = () => {
    let value = localStorage.getItem("language");
    if (value === "en") {
      i18n.changeLanguage("fr");
      localStorage.setItem("language", "fr");
      dayjs.locale("fr");
      setDefaultConfig({
        locale: frLan,
      });
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
      dayjs.locale("en");
      setDefaultConfig({
        locale: enUS,
      });
    }
  };
  if (!localStorage.getItem("language")) {
    i18n.changeLanguage("en");
    localStorage.setItem("language", "en");
    dayjs.locale("en");
  };
  return (
    <>
      <Layout>
        <Header>
        <div className="nav-wrapper w-container">
        <div className="nav-logo">
            <a href="/" className="link-block-header-logo w-inline-block w--current"><Title level={4}>DeviceSpeak</Title></a>
          </div>
          <nav class="nav-links nav-menu">
          <a href="/" className="nav-link-block w-inline-block">
            <div className="text-block-3">Home</div>
          </a>
          <a href="/login" className="nav-link-block w-inline-block">
            <div className="text-block-3">{t("Login")}</div>
          </a>
          <a className="nav-link-block w-inline-block">
                <div className="text-block-3" onClick={changeLanguage} >{localStorage.getItem("language")==="fr"?"EN":"FR"  }</div>
          </a>
          </nav>
        </div>
        </Header>
        <Content style={{  height:"91vh",overflow:"auto",backgroundColor:"#f4f7f9" }}>
        <Content className="content2">
                <Row className="Card1" >
              <Col className="Title1" xs={24} sm={24} md={12} >
                <div className="billboard-content">
            <div className="heading-01 hero">{t("content.1")}</div>
                <div className="sub-headline hero">
                {t("content.2")}
                </div>
            </div>
              </Col>
              <Col xs={24} sm={24} md={12} style={{ margin:"auto" }}>
              <div className="billboard-video">
                <Carousel   autoplay>
                <div>
                  <Image
                    src={image1}
                    preview={false}
                  />
                </div>
                <div>
                  <Image
                    src={image2}
                    preview={false}
                  />
                </div>
                <div>
                  <Image
                    src={image3}
                    preview={false}
                  />
                </div>
                <div>
                  <Image
                    src={image4}
                    preview={false}
                  />
                </div>
              </Carousel></div>
              </Col>
            </Row>
      </Content>
      <Content className="content2">
                <Card
                  bordered={true}
                  className="HomeCard2"
                >
                <Row  style={{ margin:"30px 5px" }}>
                  <Col>
                  <h3 className="HomeTitel">{t("content.12")}</h3>
                  <div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.13")}</div>
                  </Col>
                </Row>
                <div style={{ margin:"40px 0px" }}>
                <Row gutter={[16, 16]} >
                  <Col xs={24} sm={24} md={12} style={{ margin:"auto",paddingLeft:"3%" }} >
                    <h3 className="HomeTitels">{t("content.3")}</h3>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.4")}</div></Col>
                    </Row>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.5")}</div></Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={12} style={{ textAlign:'center' }}>
                  <div>
                  <Image
                    src={image5}
                    preview={false}
                    style={{ borderRadius: 10,width:"80%" }}
                  />
                </div>
                  </Col>
                </Row></div>
                <div style={{ margin:"40px 0px" }}>
                <Row gutter={[16, 16]} style={{ margin:"40px 0px" }}>
                  <Col xs={24} sm={24} md={13} style={{ textAlign:'center' }}>
                  <Image
                    src={image7}
                    preview={false}
                    style={{ borderRadius: 10,width:"80%" }}
                  />
                  </Col>
                  <Col xs={24} sm={24} md={11} style={{ margin:"auto" }} >
                    <h3 className="HomeTitels">{t("content.6")}</h3>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.7")}</div></Col>
                    </Row>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.8")}</div></Col>
                    </Row>
                  </Col>
                </Row></div>
                <div style={{ margin:"40px 0px" }}>
                <Row gutter={[16, 16]} style={{ margin:"40px 0px" }}>
                  <Col xs={24} sm={24} md={12} style={{ margin:"auto",paddingLeft:"3%" }}>
                    <h3 className="HomeTitels">{t("content.9")}</h3>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.10")}</div></Col>
                    </Row>
                    <Row gutter={[5, 16]}>
                      <Col span={2}><CheckOutlined className="TikSvg"/></Col>
                      <Col span={22}><div className="sub-headline hero"  style={{ whiteSpace:'break-spaces' }}>{t("content.11")}</div></Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={12} style={{ textAlign:'center' }}>
                  <Image
                    src={image6}
                    preview={false}
                    style={{ borderRadius: 10,width:"80%" }}
                  />
                  </Col>
                </Row></div>
              
                </Card>
    </Content>
      </Content>
      </Layout>
    </>
  );
}

export default NonAuthHome;
